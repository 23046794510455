export const intToStringTime = {
    0: "12:00 AM", 1: "12:05 AM", 2: "12:10 AM", 3: "12:15 AM", 4: "12:20 AM", 5: "12:25 AM", 6: "12:30 AM", 7: "12:35 AM", 
    8: "12:40 AM", 9: "12:45 AM", 10: "12:50 AM", 11: "12:55 AM", 12: "1:00 AM", 13: "1:05 AM", 14: "1:10 AM", 15: "1:15 AM",
     16: "1:20 AM", 17: "1:25 AM", 18: "1:30 AM", 19: "1:35 AM", 20: "1:40 AM", 21: "1:45 AM", 22: "1:50 AM", 23: "1:55 AM",
      24: "2:00 AM", 25: "2:05 AM", 26: "2:10 AM", 27: "2:15 AM", 28: "2:20 AM", 29: "2:25 AM", 30: "2:30 AM", 31: "2:35 AM",
       32: "2:40 AM", 33: "2:45 AM", 34: "2:50 AM", 35: "2:55 AM", 36: "3:00 AM", 37: "3:05 AM", 38: "3:10 AM", 39: "3:15 AM",
        40: "3:20 AM", 41: "3:25 AM", 42: "3:30 AM", 43: "3:35 AM",
    44: "3:40 AM", 45: "3:45 AM", 46: "3:50 AM", 47: "3:55 AM", 48: "4:00 AM", 49: "4:05 AM", 50: "4:10 AM", 51: "4:15 AM",
    52: "4:20 AM", 53: "4:25 AM", 54: "4:30 AM", 55: "4:35 AM", 56: "4:40 AM", 57: "4:45 AM", 58: "4:50 AM", 59: "4:55 AM",
    60: "5:00 AM", 61: "5:05 AM", 62: "5:10 AM", 63: "5:15 AM", 64: "5:20 AM", 65: "5:25 AM", 66: "5:30 AM", 67: "5:35 AM",
    68: "5:40 AM", 69: "5:45 AM", 70: "5:50 AM", 71: "5:55 AM", 72: "6:00 AM", 73: "6:05 AM", 74: "6:10 AM", 75: "6:15 AM",
    76: "6:20 AM", 77: "6:25 AM", 78: "6:30 AM", 79: "6:35 AM", 80: "6:40 AM", 81: "6:45 AM", 82: "6:50 AM", 83: "6:55 AM",
    84: "7:00 AM", 85: "7:05 AM", 86: "7:10 AM", 87: "7:15 AM", 88: "7:20 AM", 89: "7:25 AM", 90: "7:30 AM", 91: "7:35 AM",
    92: "7:40 AM", 93: "7:45 AM", 94: "7:50 AM", 95: "7:55 AM", 96: "8:00 AM", 97: "8:05 AM", 98: "8:10 AM", 99: "8:15 AM", 
    100: "8:20 AM", 101: "8:25 AM", 102: "8:30 AM", 103: "8:35 AM", 104: "8:40 AM", 105: "8:45 AM", 106: "8:50 AM", 107: "8:55 AM",
     108: "9:00 AM", 109: "9:05 AM", 110: "9:10 AM", 111: "9:15 AM", 112: "9:20 AM", 113: "9:25 AM", 114: "9:30 AM", 115: "9:35 AM",
      116: "9:40 AM", 117: "9:45 AM", 118: "9:50 AM", 119: "9:55 AM", 120: "10:00 AM", 121: "10:05 AM", 122: "10:10 AM", 123: "10:15 AM",
       124: "10:20 AM", 125: "10:25 AM", 126: "10:30 AM", 127: "10:35 AM",
    128: "10:40 AM", 129: "10:45 AM", 130: "10:50 AM", 131: "10:55 AM", 132: "11:00 AM", 133: "11:05 AM", 134: "11:10 AM", 135: "11:15 AM",
     136: "11:20 AM", 137: "11:25 AM", 138: "11:30 AM", 139: "11:35 AM", 140: "11:40 AM", 141: "11:45 AM", 142: "11:50 AM", 143: "11:55 AM",
      144: "12:00 PM", 145: "12:05 PM", 146: "12:10 PM", 147: "12:15 PM", 148: "12:20 PM", 149: "12:25 PM", 150: "12:30 PM", 151: "12:35 PM", 
      152: "12:40 PM", 153: "12:45 PM", 154: "12:50 PM", 155: "12:55 PM", 156: "1:00 PM", 157: "1:05 PM", 158: "1:10 PM", 159: "1:15 PM", 160: 
      "1:20 PM", 161: "1:25 PM", 162: "1:30 PM", 163: "1:35 PM", 164: "1:40 PM", 165: "1:45 PM", 166: "1:50 PM", 167: "1:55 PM", 168: "2:00 PM",
       169: "2:05 PM", 170: "2:10 PM", 171: "2:15 PM", 172: "2:20 PM", 173: "2:25 PM", 174: "2:30 PM", 175: "2:35 PM", 176: "2:40 PM", 177: "2:45 PM",
        178: "2:50 PM", 179: "2:55 PM", 180: "3:00 PM", 181: "3:05 PM", 182: "3:10 PM", 183: "3:15 PM", 184: "3:20 PM", 185: "3:25 PM", 186: "3:30 PM",
        187: "3:35 PM", 188: "3:40 PM", 189: "3:45 PM", 190: "3:50 PM", 191: "3:55 PM", 192: "4:00 PM", 193: "4:05 PM", 194: "4:10 PM", 195: "4:15 PM",
         196: "4:20 PM", 197: "4:25 PM", 198: "4:30 PM", 199: "4:35 PM", 200: "4:40 PM", 201: "4:45 PM", 202: "4:50 PM", 203: "4:55 PM", 204: "5:00 PM",
          205: "5:05 PM", 206: "5:10 PM", 207: "5:15 PM", 208: "5:20 PM", 209: "5:25 PM", 210: "5:30 PM", 211: "5:35 PM", 212: "5:40 PM", 213: "5:45 PM",
           214: "5:50 PM", 215: "5:55 PM", 216: "6:00 PM", 217: "6:05 PM", 218: "6:10 PM", 219: "6:15 PM", 220: "6:20 PM", 221: "6:25 PM", 222: "6:30 PM",
            223: "6:35 PM", 224: "6:40 PM", 225: "6:45 PM", 226: "6:50 PM", 227: "6:55 PM", 228: "7:00 PM", 229: "7:05 PM", 230: "7:10 PM", 231: "7:15 PM",
             232: "7:20 PM", 233: "7:25 PM", 234: "7:30 PM", 235: "7:35 PM", 236: "7:40 PM", 237: "7:45 PM", 238: "7:50 PM", 239: "7:55 PM", 240: "8:00 PM", 241: "8:05 PM", 242: "8:10 PM", 243: "8:15 PM", 244: "8:20 PM", 245: "8:25 PM", 246: "8:30 PM", 247: "8:35 PM", 248: "8:40 PM", 249: "8:45 PM", 250: "8:50 PM", 251: "8:55 PM", 252: "9:00 PM", 253: "9:05 PM", 254: "9:10 PM", 255: "9:15 PM", 256: "9:20 PM", 257: "9:25 PM", 258: "9:30 PM", 259: "9:35 PM", 260: "9:40 PM", 261: "9:45 PM", 262: "9:50 PM", 263: "9:55 PM", 264: "10:00 PM", 265: "10:05 PM", 266: "10:10 PM", 267: "10:15 PM", 268: "10:20 PM", 269: "10:25 PM", 270: "10:30 PM", 271: "10:35 PM", 272: "10:40 PM", 273: "10:45 PM", 274: "10:50 PM", 275: "10:55 PM", 276: "11:00 PM",
    277: "11:05 PM", 278: "11:10 PM", 279: "11:15 PM", 280: "11:20 PM", 281: "11:25 PM", 282: "11:30 PM", 283: "11:35 PM", 284: "11:40 PM", 285: "11:45 PM", 286: "11:50 PM", 287: "11:55 PM"
}



export function getDateInFormat(date = null) {
    if (date) {
        const dete = new Date(date);
        let day = dete.getDate();
        const year = dete.getFullYear();
        let month = (dete.getMonth() + 1).toString();
        if (month.length === 1) {
          month = "0" + month;
        }
        if (day.toString().length === 1) {
            day = "0" + day;
        }
        const dateString = `${year}-${month}-${day}`;

        return dateString;
    }
    const dete = new Date();
    let day = dete.getDate();
    const year = dete.getFullYear();
    let month = (dete.getMonth() + 1).toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
        day = "0" + day;
    }
    const dateString = `${year}-${month}-${day}`;

    return dateString;
  }



export function getTimes(open, close) {
    let times = []; // [4:30 AM, 4:35 AM]
    let start = stringToIntTime[open]; // opens at 4:30 AM 54
    let end = stringToIntTime[close]; // closes at 8:00 PM 240 [100]
    while (start <= end) { // 54 240 55 
        times.push(intToStringTime[start]);
        start++;
    }
    return times;
}


export function getTime(time) {

    let ap;
    let numbo;
    let mino;
    let timeArr = time.split('');
    mino = `${timeArr[timeArr.length - 2]}5`;
    let num = parseInt(time.split(":")[0]);
    if (num >= 12) {
        ap = "PM";
    }
    else {
        ap = "AM";
    }
    if (num === 0) {
        numbo = 12;
    }
    else if (num > 12) {
        numbo = num - 12;
    }
    else {
        numbo = num;
    }
    if (mino === ":5") {
        mino = " 5";
    }


    return {
        ap,
        hour: numbo,
        minute: mino === " 5" ? "05" : mino
    }
}


export function createMaplist(arrayOfObjects, displayedName) {
    if (arrayOfObjects.length > 0) {
    const newArray = [];
    for (let i = 0; i < arrayOfObjects.length; i++) {
        let obj = {"displayName": arrayOfObjects[i][displayedName], id: arrayOfObjects[i]["_id"]};
        newArray.push(obj);
    }
    return newArray;
    }
}

export const stringToIntTime = {
    "12:00 AM": 0, "12:05 AM": 1, "12:10 AM": 2, "12:15 AM": 3, "12:20 AM": 4, "12:25 AM": 5, "12:30 AM": 6,
    "12:35 AM": 7, "12:40 AM": 8, "12:45 AM": 9, "12:50 AM": 10, "12:55 AM": 11, "1:00 AM": 12, "1:05 AM": 13, "1:10 AM": 14,
    "1:15 AM": 15, "1:20 AM": 16, "1:25 AM": 17, "1:30 AM": 18, "1:35 AM": 19, "1:40 AM": 20, "1:45 AM": 21, "1:50 AM": 22,
    "1:55 AM": 23, "2:00 AM": 24, "2:05 AM": 25, "2:10 AM": 26, "2:15 AM": 27, "2:20 AM": 28, "2:25 AM": 29, "2:30 AM": 30,
    "2:35 AM": 31, "2:40 AM": 32, "2:45 AM": 33, "2:50 AM": 34, "2:55 AM": 35, "3:00 AM": 36, "3:05 AM": 37, "3:10 AM": 38,
    "3:15 AM": 39, "3:20 AM": 40, "3:25 AM": 41, "3:30 AM": 42, "3:35 AM": 43, "3:40 AM": 44, "3:45 AM": 45, "3:50 AM": 46,
    "3:55 AM": 47, "4:00 AM": 48, "4:05 AM": 49, "4:10 AM": 50, "4:15 AM": 51, "4:20 AM": 52, "4:25 AM": 53, "4:30 AM": 54
    , "4:35 AM": 55, "4:40 AM": 56, "4:45 AM": 57, "4:50 AM": 58, "4:55 AM": 59, "5:00 AM": 60, "5:05 AM": 61, "5:10 AM": 62,
    "5:15 AM": 63, "5:20 AM": 64, "5:25 AM": 65, "5:30 AM": 66, "5:35 AM": 67, "5:40 AM": 68, "5:45 AM": 69, "5:50 AM": 70,
    "5:55 AM": 71, "6:00 AM": 72, "6:05 AM": 73, "6:10 AM": 74, "6:15 AM": 75, "6:20 AM": 76, "6:25 AM": 77, "6:30 AM": 78,
    "6:35 AM": 79, "6:40 AM": 80, "6:45 AM": 81, "6:50 AM": 82, "6:55 AM": 83, "7:00 AM": 84, "7:05 AM": 85, "7:10 AM": 86,
    "7:15 AM": 87, "7:20 AM": 88, "7:25 AM": 89, "7:30 AM": 90, "7:35 AM": 91, "7:40 AM": 92, "7:45 AM": 93, "7:50 AM": 94,
    "7:55 AM": 95, "8:00 AM": 96, "8:05 AM": 97, "8:10 AM": 98, "8:15 AM": 99, "8:20 AM": 100, "8:25 AM": 101, "8:30 AM": 102,
    "8:35 AM": 103, "8:40 AM": 104, "8:45 AM": 105, "8:50 AM": 106, "8:55 AM": 107, "9:00 AM": 108, "9:05 AM": 109,
    "9:10 AM": 110, "9:15 AM": 111, "9:20 AM": 112, "9:25 AM": 113, "9:30 AM": 114, "9:35 AM": 115, "9:40 AM": 116,
    "9:45 AM": 117, "9:50 AM": 118, "9:55 AM": 119, "10:00 AM": 120, "10:05 AM": 121, "10:10 AM": 122, "10:15 AM": 123,
    "10:20 AM": 124, "10:25 AM": 125, "10:30 AM": 126, "10:35 AM": 127, "10:40 AM": 128, "10:45 AM": 129, "10:50 AM": 130,
    "10:55 AM": 131, "11:00 AM": 132, "11:05 AM": 133, "11:10 AM": 134, "11:15 AM": 135, "11:20 AM": 136, "11:25 AM": 137,
    "11:30 AM": 138, "11:35 AM": 139, "11:40 AM": 140, "11:45 AM": 141, "11:50 AM": 142, "11:55 AM": 143, "12:00 PM": 144,
    "12:05 PM": 145, "12:10 PM": 146, "12:15 PM": 147, "12:20 PM": 148, "12:25 PM": 149, "12:30 PM": 150, "12:35 PM": 151,
    "12:40 PM": 152, "12:45 PM": 153, "12:50 PM": 154, "12:55 PM": 155, "1:00 PM": 156, "1:05 PM": 157, "1:10 PM": 158, "1:15 PM": 159, "1:20 PM": 160, "1:25 PM": 161,
    "1:30 PM": 162, "1:35 PM": 163, "1:40 PM": 164, "1:45 PM": 165, "1:50 PM": 166, "1:55 PM": 167, "2:00 PM": 168, "2:05 PM": 169,
    "2:10 PM": 170, "2:15 PM": 171, "2:20 PM": 172, "2:25 PM": 173, "2:30 PM": 174, "2:35 PM": 175, "2:40 PM": 176, "2:45 PM": 177,
    "2:50 PM": 178, "2:55 PM": 179, "3:00 PM": 180, "3:05 PM": 181, "3:10 PM": 182, "3:15 PM": 183, "3:20 PM": 184, "3:25 PM": 185,
    "3:30 PM": 186, "3:35 PM": 187, "3:40 PM": 188, "3:45 PM": 189, "3:50 PM": 190, "3:55 PM": 191, "4:00 PM": 192, "4:05 PM": 193,
    "4:10 PM": 194, "4:15 PM": 195, "4:20 PM": 196, "4:25 PM": 197, "4:30 PM": 198, "4:35 PM": 199, "4:40 PM": 200, "4:45 PM": 201,
    "4:50 PM": 202, "4:55 PM": 203, "5:00 PM": 204, "5:05 PM": 205, "5:10 PM": 206, "5:15 PM": 207, "5:20 PM": 208, "5:25 PM": 209,
    "5:30 PM": 210, "5:35 PM": 211, "5:40 PM": 212, "5:45 PM": 213, "5:50 PM": 214, "5:55 PM": 215, "6:00 PM": 216, "6:05 PM": 217,
    "6:10 PM": 218, "6:15 PM": 219, "6:20 PM": 220, "6:25 PM": 221, "6:30 PM": 222, "6:35 PM": 223, "6:40 PM": 224, "6:45 PM": 225,
    "6:50 PM": 226, "6:55 PM": 227, "7:00 PM": 228, "7:05 PM": 229, "7:10 PM": 230, "7:15 PM": 231, "7:20 PM": 232, "7:25 PM": 233,
    "7:30 PM": 234, "7:35 PM": 235, "7:40 PM": 236, "7:45 PM": 237, "7:50 PM": 238, "7:55 PM": 239, "8:00 PM": 240, "8:05 PM": 241,
    "8:10 PM": 242, "8:15 PM": 243, "8:20 PM": 244, "8:25 PM": 245, "8:30 PM": 246, "8:35 PM": 247, "8:40 PM": 248, "8:45 PM": 249,
    "8:50 PM": 250, "8:55 PM": 251, "9:00 PM": 252, "9:05 PM": 253, "9:10 PM": 254, "9:15 PM": 255, "9:20 PM": 256, "9:25 PM": 257,
    "9:30 PM": 258, "9:35 PM": 259, "9:40 PM": 260, "9:45 PM": 261, "9:50 PM": 262, "9:55 PM": 263, "10:00 PM": 264, "10:05 PM": 265,
    "10:10 PM": 266, "10:15 PM": 267, "10:20 PM": 268, "10:25 PM": 269, "10:30 PM": 270, "10:35 PM": 271, "10:40 PM": 272, "10:45 PM": 273,
    "10:50 PM": 274, "10:55 PM": 275, "11:00 PM": 276, "11:05 PM": 277, "11:10 PM": 278, "11:15 PM": 279, "11:20 PM": 280, "11:25 PM": 281,
    "11:30 PM": 282, "11:35 PM": 283, "11:40 PM": 284, "11:45 PM": 285, "11:50 PM": 286, "11:55 PM": 287
}


export function getTimeRightAway() {
    let ampm = "";
    let hour = new Date().getHours();
    if (hour > 12) {
        hour = hour - 12;
        ampm = "PM";
    }
    else {
        ampm = "AM";
    }
    let minutesArray = new Date().getMinutes().toString().split("");
    if (minutesArray.length === 2) {
        if (minutesArray[0] === "5" && minutesArray[1] > 5) {
            hour = hour + 1;
            minutesArray[0] = 0;
            minutesArray[1] = 0;
        }
        else if (minutesArray[1] > 5) {
            minutesArray[1] = "0"
            minutesArray[0] = (Number(minutesArray[0]) + 1).toString();
         } 
         else {
            minutesArray[1] = "5";
         }
    }
    else {
        if (minutesArray[0] > 5) {
            minutesArray[0] = "1"
            minutesArray[1] = "0" 
         } 
         else if (minutesArray > 0 && minutesArray < 6) {
            minutesArray[0] = "05";
         }
         else {
             minutesArray[0] = "00";
         }
    }
    let minutes = minutesArray.join("");
    if (hour === "0" || hour === 0) {
        hour = 12;
    }
    return `${hour}:${minutes} ${ampm}`

}




// Import the functions you need from the SDKs you need



// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional


export const firebaseConfig = {

    apiKey: "AIzaSyBGIq6hud26ASSt0f9Uf_8z3U-rJMbJ2us",
  
    authDomain: "mfcrestaurant-5bb7f.firebaseapp.com",
  
    projectId: "mfcrestaurant-5bb7f",
  
    storageBucket: "mfcrestaurant-5bb7f.appspot.com",
  
    messagingSenderId: "1056403612661",
  
    appId: "1:1056403612661:web:ce9bc55caaf250c81136bc",
  
    measurementId: "G-NPLSN84KNJ",
    
    databaseURL: "https://mfcrestaurant-5bb7f-default-rtdb.firebaseio.com/"
}  


export const members = {
"A047": "Elizabeth Wiseman",
"A117": "Beth Field",
"A150": "Suzanne Coffee",
"A233": "Jeffrey Dubrow",
"A613J": "Cynthia Ireland",
"A633": "Patricia Joyner",
"A633K": "Susan Kenuk",
"A745": "Judy MacMillan",
"b004": "Michael Mercer",
"B006": "Robert Plant",
"B021": "Barbara Rossano",
"B024": "Mary Sewell",
"B024A": "Laurence Tait",
"B024z": "Edward Workman",
"B067": "Andrew Litwack",
"B1011": "Francis Fisher Jr.",
"B102": "Marie Quigley",
"B107": "Beth Runfola",
"B120": "Tara Hinshillwood",
"B144": "Becky Ford",
"B158": "Michael Ford",
"B193": "Dina McCabe",
"B193a": "Chuck McCabe",
"B200": "Marcy Toussaint Elwell",
"B220": "Scott Whitley",
"B223": "Gregory Horn",
"B224": "Brett Mead",
"B224j": "Michael St. John",
"B241": "Tyler Mead",
"B270": "Cole Nicolai",
"B315": "Michael Milone",
"B325": "Michael DiMarino",
"B405": "Kosta Kalavruzos",
"B407": "John Locatell",
"B415": "Jeremy Kochman",
"B526": "Mark Sullivan",
"B564": "John Carpinelli",
"B602": "Douglas Sell Jr.",
"B618": "John Vastardis",
"B635": "Joseph McAleer",
"B661": "Michael Worth",
"B711": "Lewis Curran",
"B716": "James Kenville",
"B716a": "Dan Pruitt",
"B732": "Josh Feldschneider",
"B732a": "Jeff Adeli",
"B808": "Gary Carty",
"B816": "Timothy Casale",
"B816A": "Robert Kirk",
"C006": "Mark Armstrong",
"C025": "Francisco Allende",
"C031": "Joseph Aristone",
"C084": "Kevin Bennett",
"C104": "Greg Bronczyk",
"C108": "Timothy Button",
"C112": "Robert Birdsall",
"C124": "Jeffrey Beam",
"C125": "Chris Brogan-Firesinger",
"C125T": "Thomas Burns",
"C130": "Robert Bickel",
"C132M": "Brian Baskin",
"C176": "Seth Berk",
"C185": "James Burns",
"C192": "Brian Celhar",
"C201": "Harry Crouch",
"C216": "Michael Cantwell",
"C230": "Geoffrey Celhar",
"C230a": "Richard Calabrese",
"C250": "Ken Carlton",
"C260": "Paul Canton III",
"C322": "Christopher Chesner",
"C330": "Colin Cairns",
"C350": "Rick Crane",
"C350A": "Jeffrey Debs",
"C382": "Mike Distler",
"C382A": "Robert Dugan",
"C402": "James Dee",
"C403": "David Duffy",
"C405": "Stephen DeNittis",
"C504": "Patrick DiSpirito",
"C515": "John Durham",
"C515C": "Douglas Dreby",
"C521": "James Elder",
"C604": "Peter Ellison",
"C609": "Steven Engle",
"C609C": "Thomas Fordham",
"C707": "Bernard Flynn",
"C717": "Patrick Fowler",
"C720": "Walter Fazler",
"C730": "William Gill",
"C740": "Richard Gillece",
"C743": "Alan Gudis",
"C743P": "Joseph Gregorio Jr.",
"C902": "Brian Gorman",
"C922": "Tom Godonis",
"C922d": "Earl Geertgens",
"C923": "Daniel Gannon",
"C926": "Chris Gheysens",
"C999": "Craig Greenwood",
"D001": "Robert Gipson",
"D020": "Steve Garin",
"D021": "Paul Gilhool",
"D050": "Lee Hummel",
"D090": "John Humes",
"D126": "Ryan Hanratty",
"D142": "William Andy Hall",
"D157": "James Horsley",
"D200a": "Adam Haraburda",
"D215": "Frederick Hirsekorn",
"D218": "Charles Hartsell",
"D219": "Chuck Holmgren",
"D235a": "Mark Hansinger",
"D250": "Mark Kouch",
"D316": "Calvin Knowlton",
"D390": "Drew Krisanda",
"D390": "Bob Kennedy",
"D407": "Jake Kramer",
"D410": "Thomas Londres",
"D434": "Gregory Lofgren",
"D434H": "Matthew Lesneski",
"D512": "Gerald Miller",
"D535": "Harrison Morris",
"D623": "Jonathan McGugan",
"D677": "Rich Meintel",
"D703": "Brian Moody",
"D740": "Matthew Morris",
"D741": "Michael McCool",
"D755": "Don Mishler",
"D900": "Robert McLaughlin",
"E003": "Kenneth Massa",
"E040": "Craig Markel",
"E127": "Jonathan Mabry",
"E127a": "Paul Martin",
"E169": "Fred Mascoveto",
"E200": "Sharad Mansukani",
"E270": "Robert Mazza",
"E270R": "Patrick McIlroy",
"E301": "Jeff Muhlschlegel",
"E366": "Garrett McVaugh",
"E412": "Daniel Mullin",
"E412A": "John Napoli",
"E527": "Gary O'Brien",
"E729": "Jeff O'Connor",
"F044": "Mark Oteri",
"F044B": "Charles Pedrick",
"F060": "Daniel Pomerantz",
"F110": "Robert Phillips",
"F111": "John Petrycki",
"F112": "A. Leigh Powell",
"F112a": "Nicholas Repici",
"F116": "Raymond Reis",
"F119a": "Jack Robinson",
"F132": "Daniel Rohrbach",
"F181": "Leonard Ridilla",
"F201": "Matthew Riggins",
"F274": "Steven Rothman",
"F400": "Andrew Stanhope",
"F493": "Stephen Sawin",
"F568": "Roy Shubert",
"F600": "William Shelden, Jr.",
"F652": "James Stavros",
"G002": "Thomas Savage",
"G003": "Eric Schmoll",
"G005": "Matthew Simeone",
"G005S": "James Salman",
"G011": "Thomas Trasser",
"G028": "Thomas Tone",
"G030": "Chris Todd",
"G063": "Michael Vermes III",
"G072": "Mike Vermes Jr.",
"G074": "William VanFossen",
"G094": "James Weinstein",
"G100": "Michael Wilson",
"G109": "Scott Wallace",
"G123": "Robert Wiseman",
"G134": "James Walton",
"G220": "Norman Wilson",
"G221": "Fredrick Young",
"G231": "Frank Angelini",
"G306": "Robert Bertino",
"G310": "Jeff Banasz",
"G410": "Chris Bain",
"G413": "Larry Cantwell",
"G432": "Andrew Chi",
"G457": "Tyler Dyer",
"G475": "Eddie Decker",
"G475A": "Keith Donnan",
"G504": "Ezra Ernst",
"G600": "Joe Finazzo",
"G600a": "Scott Gibson",
"G823": "Jon Greer",
"H002": "Edward Hochberg",
"H038": "Steven Kravitz",
"H052": "Rob Kania",
"H052A": "Colin Kravitz",
"H110": "Michael Leese",
"H114": "Thomas McDonnell",
"H146": "Michael McCloskey",
"H146a": "Frank Manion",
"H164": "Brian McCarthy",
"H200": "Andrew Phelan",
"H213": "Craig Reiner",
"H213A": "Matthew Robinson",
"H223": "James Trakis",
"H223H": "Michael Testa",
"H230": "James Wildish",
"H235": "Brendan Quinn",
"H260": "Corey E. Becker",
"H292": "Douglas Lee",
"H345": "Kenneth Depew",
"H405": "Matt Huston",
"H410": "Stephen Selverian",
"H413": "Mark Marsan",
"H420": "David Simpson",
"H420M": "Greg Johnson",
"H505": "Stephen Donovan",
"H523": "James Blong",
"H606": "Andrew Phillips",
"H741": "Steven Ems",
"H817": "Gerald McBride",
"H904": "Adam Brooks",
"I203": "Brian Catanella",
"J027": "Brendan Williams",
"J146": "Mark Godofsky",
"J309": "Charles Esposito",
"J516": "Larry Mulligan",
"J516A": "Paul O'Donnell",
"K021": "Patrick Thornton",
"K024": "Josh Nyikita",
"K105": "Mark Belland",
"K105A": "Kyle Richards",
"K134": "Kevin Kramarski",
"K134A": "James Kramarski",
"K180": "Timothy P. Speiss",
"K180A": "Corey Smith",
"K215": "Brad Bayard",
"K221": "Brian Flynn",
"K258": "Lynn McGrath",
"K260": "Bart Schindler",
"K324": "Joseph Kochuba",
"K343": "David Rebuck",
"K396": "Sean O'Sullivan",
"K402": "James L Greene",
"K407": "Timothy Monahan",
"K413": "William Walker",
"k433": "Peter Vosbikian",
"K501": "Don Chierici",
"K609": "John Motzel",
"K633": "Steve Catone",
"K654": "Charles Berry",
"K713k": "Will Cosden",
"K713t": "James W Scott",
"K728": "William Handlan",
"L021": "Thomas Cassel",
"L021a": "Bill Blanche",
"L099": "Adam Shaw",
"L1004": "Jacob Vermes",
"L104": "Dan Karpen",
"L141": "Josh Horn",
"L163": "William Webb",
"L201": "Jeff Lounsbery",
"L201N": "Samuel Stengel",
"L213": "Andrew Scott",
"L241": "Graham Menzies",
"L252": "Greg Tocco",
"L252J": "Paul Lonergan",
"L515": "Gerald Pacella",
"L515E": "Brian Macmillan",
"L526": "Robert Woods",
"L555": "Joseph Filippoli",
"L700": "Michael Bunnick",
"L704": "Thomas Miller",
"L753": "Rick Rohrbach",
"L817": "Bob Daglian",
"M001": "Bill Emerson",
"M003": "Marc Labroli",
"M006": "Thomas Morris",
"M012": "Stephen Piro",
"M012A": "David Ricci",
"M013": "Louis Ventresca",
"M017": "Mark Caprarola",
"M022": "Chris Locatell",
"M025": "Kevin O'Sullivan",
"M046": "Brian Paquette",
"M046d": "Michael Thornton",
"M078": "Mike Locatell",
"M086": "Paul Ferretti",
"M100": "Ryan Finley",
"M121": "Mark Villanueva",
"M121A": "Alfred Salvato",
"M121t": "John DeSilvio",
"M127": "Kyungnho Taco Kim",
"M139": "Shawn Tilger",
"M145": "Chad Davis",
"M151": "Tracy Newport",
"M163": "Matthew Fiori",
"M171": "Mark Potosky",
"M171A": "John Fitch",
"M208": "Stephen Eigenrauch",
"M218": "Greg Emmett",
"M220": "John Petrone",
"M223": "Mike Gamble",
"M233": " ",
"M242": "Scott Ferguson",
"M243": "Timothy D'Angelo",
"M248": "Christopher Widell",
"M248J": "Robin Ricci",
"M307": "Karen Greer",
"M312": "Robert McDermott",
"M319": "Howard Seshens",
"M324": "Frank Busa",
"M346": "Nick Busa",
"M379": "Alan Cills",
"M390": "Anthony Casale",
"M408": "Kevin Callahan",
"M411": "Bill Corbi",
"M412": "John Diepold",
"M413": "John Daglian",
"M417": "Brian Deam",
"M504": "Jack Evans",
"M546": "Gerald Gares",
"M556": "John Gibson",
"M609": "John Haag",
"M623": "John Kallelis",
"M660": "Dean Kinsey",
"M660A": "Michael McMahon",
"M700": "William Morsell",
"M740": "Joseph Mazzarelli",
"M800": "John McQuilkin",
"M800a": "Donald Powell",
"M808": "Harold Ruff Jr.",
"M824": "Paul Riggins",
"M918": "Samuel Schlindwein",
"M918M": "John Cunningham",
"N012": "Jeffrey Hayman",
"N126": "Mike Jones",
"N747": "William Read",
"N923": "Brian Connelly",
"O011": "Matthew Vollmer",
"O130": "Stephen Gallagher",
"O148h": "Ray Schweighofer",
"O406": "Jim DeHart",
"O809": "Nancy Coleman",
"O809s": "Jennifer Canton III",
"O940": "Dana Krisanda",
"P001": "Stephanie Morrison",
"P002": "CJ Mascoveto",
"P010": "Patti Pomerantz",
"P015": "Peter Palko",
"P022": "Charles Steiner",
"P045": "Julia Sullivan",
"P069": "Pamela Tobias",
"P114": "Suzanne Ernst",
"P114d": "Deborah Hochberg",
"P199": "Scott Shubert",
"P345": "Melissa Brown",
"P345A": "Adrienne Eigenrauch",
"P360": "Sungtae Lim",
"P398": "Denise S. Haines",
"P400": "Andrea Benatta",
"P405": "Angela Rekant",
"P540": "Seth Broder",
"P601": "Kathryn Talone",
"P601A": "Kathryn Staub",
"P765": "Zoe Salman",
"P809": "Andrew Hall",
"P809A": "Erica Lounsbery",
"q002": "Daniela Riggins",
"Q212": "Peter Crino",
"R025": "Tracey Coffen",
"R025s": "Kathy Goldenberg",
"R036": "Haleigh Dalmass",
"R036a": "Wayne Streibich",
"R052": "Linda Lazzaro Kapostas",
"R109": "Brian Bianco",
"R116": "Donald Bianco",
"R135": "Cecile Buzzerd",
"R148": "Meredith Broder",
"R150": "William Cody",
"R234": "Thomas Coleman",
"r271": "Carolyn Cavuto",
"R276": "Steven Cohen",
"R317": "Ken Catanella",
"R317R": "Jody Demas",
"R318": "Jeanie Gamble",
"R319": "Stephen Graham",
"R334": "Lauren Greer",
"R417": "Barbara Hendrickson",
"R540": "Susan Horn",
"R620": "Mi Lim",
"R620D": "Linda Leonard",
"R700": "Nicole Milstead",
"R709": "George Morrison",
"R733": "Craig Mehnert",
"S012": "Tracey McCarthy",
"S012Z": "Laurie Palko",
"S020": "Tim Richards",
"S020K": "Henry Schwarz",
"S022": "Robyn Steiner",
"S059": "Michele Sullivan",
"S066": "Chris Sparacio",
"S069": "Scot Tobias",
"S070": "Thomas Walsh",
"S1002": "Cary Brown",
"S1030": "Jill Kaeser",
"S104": "Beth VanDusen",
"S108": "Lon C. Haines",
"S132": "Peter Calleo",
"S132p": "Andrea Giacobbo",
"S168": "Mark Rekant",
"S208": "Albert Talone",
"S213": "Victor Bobadilla",
"S220": "Nicholas Staub",
"S225": "Roger Lefferts",
"S228": "Jeanne Covert",
"S284": "Frank Caccuro",
"S284A": "Frances Cox",
"S322": "Suzanne Sweeney",
"S326": "Richard Sweeney",
"S362": " ",
"S432": "Jen Anderson",
"S432M": "Richard Kahn",
"S433": "Benjamin Blank",
"S445": "James Broker",
"S506": "Aaron Byrd-Leitner",
"S506A": "Kevin Crow",
"S515": "Jeff Hipple",
"S576": "Richard Jagodzinski",
"S602": "W. Reed Kindermann",
"S602A": "Nick Maravich",
"S605": "Mark Sever",
"S607": "John Twomey",
"S607r": "John Damian",
"s700": "Patrick Guise",
"S712": "Curtis Brinkman",
"S717": "Jeff Hilzinger",
"S717L": "Michael Suleta",
"S738": "John Culbertson",
"S738Z": "Deirdre Zohlman",
"s860": "Colleen DeRose",
"S873": "Lynn Rohrbach",
"T016": "John Zaorski",
"T145": "John Zohlman",
"T192": "Helen Hughes",
"T200": "Ed Petrella",
"T213": "Amy Webb",
"T225": "Arthur Coffen",
"T257": "James Tarangelo",
"T327": "Cynthia Streibich",
"T412": "Allison Byrd-Leitner",
"T503": "Michele Jagodzinski",
"T668": "Greta Kindermann",
"T727": "Julie Maravich",
"T740": "Shelly Guise",
"T740s": "Jennifer Brinkman",
"T750": "Hollie Hilzinger",
"T750a": "Laura Suleta",
"T813": "Donna Culbertson",
"T829": "Renee Salvato",
"T829A": "Deborah Pacella",
"V005": "Alexe Tsao",
"V011": "Christine Torigian",
"V024": "Briana Maravich",
"V026": "Sally Button",
"V046": "Alyssa Garin",
"V333": "Orsula Knowlton",
"V425": "Jennifer Powell",
"V507": "Annemarie Simeone",
"V630": "Lisa Siegert",
"V772": "Dana Belland",
"W010": "Marisa Edmund",
"W021": "Linda McAleer",
"W082": "Madeline Mullin",
"W096": "Maureen Handlan",
"W111": "Christine Cassel",
"W201": "Nancy Londres",
"W213": "Jean Hendrickson",
"W247": "Sarah Kirn",
"W251": "Audrey Kohart",
"W347": "Karen Mead",
"W408": "Allison Rohrbach",
"W505": "Jill Ackerly",
"W525": "Karen Bolte",
"W610": "Sue Calhoun",
"W633": "Mary Scheibner",
"W824": "Tracy Ferguson",
"W860": "Giuliana Chesner",
"W875": "Peter Simpson",
"Y050": "Brooks Pulito",
"Z006": "Ryan Lynch",
"Z006": "Tommy Kalavruzos",
"Z118": "Regina Emmett",
"Z118d": "Zach Button",
"Zachary Smith": "Zachary Smith"
}


export const firebaseConfig2 = {
    apiKey: "AIzaSyCeymuiAguKL6g15Ks0b8WcdL0ydeTnvWE",
    authDomain: "mfcscheduler.firebaseapp.com",
    projectId: "mfcscheduler",
    storageBucket: "mfcscheduler.appspot.com",
    messagingSenderId: "496250436493",
    appId: "1:496250436493:web:5769f941105d3869981bc4",
    measurementId: "G-WXQDKS5FD9"
};