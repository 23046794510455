import { intToStringTime, firebaseConfig, firebaseConfig2, stringToIntTime, members, getTime } from '../utils';
import './User.css'
import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import {getFirestore, doc, setDoc, Firestore, collection} from 'firebase/firestore';
import {getDocs, where} from 'firebase/firestore';
import { getDoc, query } from "firebase/firestore";
import DateDrop from '../BookingHelpers/DateDrop/DateDrop';
import {withRouter} from 'react-router-dom';
import OtherAlert from '../OtherAlerts/OtherAlerts';





function User(props) {
  const [open, setOpen] = useState('');
  const [close, setClose] = useState('');
  const [empty, setEmpty] = useState([]);
  const [memberNumber, setMemberNumber] = useState('');
  const [numAtTable, setNumAtTable] = useState('');
  const [seatPref, setSeatPref] = useState('');
  const [date, setDate] = useState(new Date().toDateString());
  const [time, setTime] = useState('');
  const [tableNum, setTableNum] = useState('');
  const [success, setSuccess] = useState(false);
  // const [notAMember, setNotAMember] = useState(false);
  const [error, setError] = useState("");
  const [showOrderInput, setShowOrderInput] = useState("");
  const [tables, setTables] = useState([]);
  const [orders, setOrders] = useState([]);
  const [memNumForOrder, setMemNumForOrder] = useState("");
  const [groups, setGroups] = useState([]);
  const [probTable, setProbTable] = useState();
  const [menu, setMenu] = useState([]);
  const [tablesBasedOnPreference, setTablesBasedOnPreference] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [blocks, setBlocks] = useState([]);

  React.useEffect(function() {
    const app = initializeApp(firebaseConfig2, "second");
    const db = getFirestore(app);
    getDocs(collection(db, "schedule")).then(
        response => {
            response.forEach(data => {
                setSchedule(data.data().sched);
            })
        }
    )
}, [])


  React.useEffect(function () {
    if (schedule.length) {
      console.log("I SHOULD BE RUNNING!")
        setOpen(schedule[new Date(date).getDay()].open)
        setClose(schedule[new Date(date).getDay()].close)
    }
  }, [date, schedule.length])


  // React.useEffect(function() {
  //     const app = initializeApp(firebaseConfig2, "second");
  //     const db = getFirestore(app);
  //     getDocs(collection(db, "menuGroups")).then(
  //         response => {
  //             const groupos = [...groups];
  //             response.forEach(data => {
  //                 groupos.push(data.data());
  //             })
  //             setGroups(groupos);
  //         }
  //     )
  // }, [])

  React.useEffect(function() {
      const app = initializeApp(firebaseConfig2, "second");
      const db = getFirestore(app);
      getDocs(collection(db, "menuGroups")).then(
        response => {
            const groupos = [];
            response.forEach(data => {
                groupos.push(data.data());
            })
            setMenu(groupos);      
        }
      )
  },[]);

  function checkOrderStatus() {
     setShowOrderInput(true);
  }

  function goToTakeout() {
    props.history.push('/takeout')
  }

  React.useEffect(function() {
    if (error !== "") {
         setTimeout(() => setError(""), 6000);
    }
  }, [error]);


  React.useEffect(function () {
    const emptyRep = [];
    for (let i = stringToIntTime[open]; i < stringToIntTime[close]; i++) {
      emptyRep.push(intToStringTime[i]);
    }
    setEmpty(emptyRep)
  },[close])

  

  function checkStatus() {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const orderos = [];
    const dateo = new Date().toDateString();
    const q = query(collection(db, 'orders'), where('member', '==', memNumForOrder));
    getDocs(q).then(response => {
        response.forEach(el => {
          console.log(el.data().date)
          console.log(el.data().date, new Date().toDateString())
          if (el.data().date === new Date().toDateString()) {
            orderos.push(el.data());
          }
        })
        setOrders(orderos);
    })
  }



  React.useEffect(function() {
    const app = initializeApp(firebaseConfig2, "second");
    const db = getFirestore(app);
    getDocs(collection(db, "groups")).then(
        response => {
            const groupos = [];
            response.forEach(data => {
                groupos.push(data.data());
            })
            setGroups(groupos);
            const tablos = [...tables];
            for (let i = 0; i < groupos.length; i++) {
                for (let r = 0; r < groupos[i].tables.length; r++) {
                    groupos[i].tables[r].id = groupos[i].label + groupos[i].tables[r].table_num;
                    tablos.push(groupos[i].tables[r]);
                }
            }
            setTables(tablos)
        }
      )
  }, [])

  async function booko() {
    let dater = "";
    const dateArr = date.split('-');
    if (dateArr.length > 1) {
      console.log("hello?")
        dateArr[2] = parseInt((dateArr[2])) + 1;
        dateArr[2] = dateArr[2].toString();
        dater = dateArr[0] + "-" + dateArr[1] + "-" + dateArr[2];
    }
    else {
      dater = date;
    }
   
    let datey = new Date(dater).toDateString();
    if(new Date(`${datey} ${time}`) < new Date()) {
      setError("This time/date has already passed. Please try again.")
      return;
    }

    if (!members[memberNumber]) {
      setError("This member number is not registered.")
      return;
    }
    else if (time === "") {
      setError("Please select a time.");
      return;
    }
    else if (numAtTable === "") {
      setError("Please specify the number of occupants.");
      return;
    }
    else if (seatPref === "") {
      setError("Please choose your seating preference.")
      return;
    }

    const appo = initializeApp(firebaseConfig2, "second");
    const dbo = getFirestore(appo);
    const qb = query(collection(dbo, 'blocks'), where('date', '==',  datey));
    let superSmoked = [];
    getDocs(qb).then(response => {
      const blockos = [];
     
        response.forEach(el => {
            blockos.push(el.data());
      })
      if(blockos.length) {
          for (let i = 0; i < blockos.length; i++) {
            if (stringToIntTime[blockos[i].timeStart] < stringToIntTime[time] && stringToIntTime[blockos[i].timeEnd] > stringToIntTime[time]) {
                setError("The Restaurant does not have availability at this time.")
                return;
            }
            else if (stringToIntTime[blockos[i].timeStart] > stringToIntTime[time] && stringToIntTime[blockos[i].timeStart] < (stringToIntTime[time] + 16)) {
                setError("The Restaurant does not have availability at this time.")  
                return;
            }
          }
      }
    

 



    const correctos = [];
    
    
    const reservationsCopy = [];
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
 
    const q = query(collection(db, 'res'), where('date', '==',  datey));
    getDocs(q).then(response => {
        response.forEach(el => {
            reservationsCopy.push(el.data());
    })
    for (let i = 0; i < reservationsCopy.length; i++) {
      if (reservationsCopy[i].location === seatPref) {
        correctos.push(reservationsCopy[i]);
      }

    }
    const helper = [];
    for (let i = 0; i < correctos.length; i++) {
      const numbs = [];
      for (let h = stringToIntTime[correctos[i].time] + 1; h < stringToIntTime[correctos[i].time] + 15; h++) {
        numbs.push(h);
      }
      helper.push({tableName: correctos[i].table_num, numbs})
    }

    const cantWork = [];
    for (let t = 0; t < helper.length; t++) {
      let yo = false;
      for (let i = stringToIntTime[time]; i < stringToIntTime[time] + 9; i++) {
        for (let g = 0; g < helper[t].numbs.length; g++) {
          if (i === helper[t].numbs[g]) {
            console.log(i, helper[t].numbs)
            console.log("HELLO")
            cantWork.push(helper[t].tableName)
            yo = true;
            break;
          }
        }
        if (yo) {
          break;
        }
      }
    }
    let special = [];
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].seatingLocation === seatPref) {
        special = groups[i].tables;
      }
    }
    for (let i = 0; i < special.length; i++) {
        for (let t = 0; t < cantWork.length; t++) {
           if (special[i] && special[i].id === cantWork[t]) {
            console.log(special[i], "IM SPECIAL I")
               special.splice(i, 1);
           }
        }
    }
    let tnum;
    if (special.length) {
        tnum = special[0].id;
    }
    else {
      setError("There is no table available at this time in your preferred seating section.");
      return;
    }
    const resRef = doc(collection(db, "res"));

     setDoc(resRef, {
      time,
      date: date,
      mem_num: memberNumber,
      table_num: tnum,
      id: resRef.id,
      numAtTable
    });
  })
      setTableNum("");
      setSeatPref("");
      setNumAtTable("");
      setMemberNumber("");
      setSuccess(true);
      setTimeout(() => setSuccess(false), 6000);
  }
  )}

  return (
    <div className="main">
      <p style={{fontSize: "32px", width: "360px", textAlign: "center", lineHeight: '40px', position: 'absolute', color: 'rgb(222, 255, 222)', zIndex: "100000", marginTop: '20px', height: "40px", opacity: "100%" }}>Moorestown Field Club Restaurant Reservation</p>
      <div id="sub_main">
        <p style={{width: '350px', marginLeft: "12px"}} id="welcome">Welcome to Moorestown Field Club Restaurant Table Reservation. Please enter your member number and other preferences to reserve your table. You can also choose to place a takeout order below.</p>
        <div id="cool">
        {!showOrderInput && <div id="otherCool">
        <input value={memberNumber} onChange={(e) => setMemberNumber(e.target.value)} placeholder="Member Number" className={'inny'}/>
        <select value={numAtTable} onChange={(e) => setNumAtTable(e.target.value)} placeholder="Number at Table" className='inny'>
                            <option>Number At Table</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
        </select>
        <select value={seatPref} onChange={(e) => setSeatPref(e.target.value)} className={"inny"} style={{fontSize: '18px', paddingLeft: "6px"}}>
          <option>Seating Preference</option>
          <option>No Preference</option>
          <option>Sunroom</option>
          <option>Patio</option>
          <option>Inside</option>
        </select>
        <select value={time} onChange={(e) => setTime(e.target.value)} className='inny'>
            <option>Choose Time</option>
            {empty.map(e => {
              return <option>{e}</option>
            })}
        </select>
        <div style={{display: 'flex', marginLeft: "-14px", justifyContent: "center", width: "100%"}}>
        <DateDrop setDateString={setDate}/>
        </div>
        <button onClick={booko} style={{height: '40px', cursor: 'pointer', alignSelf: 'center', marginLeft: '-35px', fontSize: "22px", width: "200px", borderRadius: "10px", boxShadow: "0px 0px 4px white", border: "none", backgroundColor: "#d5ffd5"}}>Book Reservation</button>
        <p style={{color: "white", fontSize: "20px", textAlign: "center", marginLeft: "-45px"}}>Or</p>
        <button onClick={goToTakeout} style={{height: '40px', cursor: 'pointer', alignSelf: 'center', marginLeft: '-35px', fontSize: "20px", width: "185px", boxShadow: "0px 0px 4px white", border: "none", borderRadius: "10px", backgroundColor: "lightblue"}}>Order Takeout</button>
        <button onClick={checkOrderStatus} style={{height: '40px', cursor: 'pointer', alignSelf: 'center', marginLeft: '-35px', fontSize: "20px", width: "185px", boxShadow: "0px 0px 4px white", border: "none", borderRadius: "10px", backgroundColor: "lightblue"}}>Order Status</button>
        </div>}
       {showOrderInput && <div>
        <div style={{display: "flex"}}>
        <button onClick={() => setShowOrderInput(false)} style={{backgroundColor: "lightblue", marginLeft: "-20px", padding: "4px", border: 'none', borderRadius: "4px", boxShadow: "0px 0px 4px white", fontWeight: "bold", height: "30px", cursor: "pointer"}}>Go Back</button>
        <p style={{color: "rgb(222,255,222)", fontSize: "20px", marginLeft: "12px", marginTop: "4px"}}>Enter Member Number Below</p>
        </div>
        <div style={{display: 'flex', justifyContent: "center", marginTop: "30px"}}>
        <input value={memNumForOrder} style={{height: '28px', paddingLeft: "4px", alignSelf: 'center', marginLeft: '-35px', fontSize: "18px", width: "192px", boxShadow: "0px 0px 4px white", border: "none", border: "none"}} onChange={(e) => setMemNumForOrder(e.target.value)} placeholder='Member Number'/>
        <button onClick={checkStatus} style={{height: "34px", boxShadow: "0px 0px 4px white", border: "none", cursor: "pointer", width: "100px", marginLeft: "10px", borderRadius: "8px", backgroundColor: "#d5ffd5"}}>Find Order</button>

        </div>
        {orders.length && 
            <div style={{marginLeft: "-22px", marginTop: "30px"}}>
              {orders.map(order => {
                const time = getTime(order.time);
                let real = `${time.hour}:${time.minute} ${time.ap}`;
                return <div style={{minHeight: "100px", display: "flex", justifyContent: "space-between", marginTop: "15px", width: "340px", backgroundColor: "rgb(242, 242, 242)", boxShadow: "0px 0px 4px white"}}>
                    <div>
                    <p style={{paddingTop: "6px", marginLeft: "8px"}}>Order Status: {order.complete ? "Complete": "In Progress"}</p>
                      <p style={{marginTop: "10px", marginLeft: "8px"}}>Ready By: {intToStringTime[stringToIntTime[real] + order.order.length * 2]}</p>
                    {order.order.map(item  => {
                        const splitItem = item.split(" ");
                        return <p style={{marginLeft: "8px", marginTop: "16px"}}>{menu[parseInt(splitItem[0])].menuItems[parseInt(splitItem[1])].itemName}</p>
                    })}
                    </div>
                </div>
              })}
          </div>}
        </div> }
        </div>

      </div>
      <div className="cover">
     </div>
     <OtherAlert showAlert={error !== ""} alertMessage={error} alertType={"failure"}/>
     <OtherAlert showAlert={success} alertMessage={`You have successfully booked a reservation for ${date} at ${time}.`} alertType={"success"}/>
    </div>
  );
}

export default withRouter(User);
