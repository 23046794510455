import React, { useEffect, useState } from 'react';
import { stringToIntTime, intToStringTime, getTime, firebaseConfig2 } from '../utils';
import { withRouter } from "react-router-dom";
import BookingHelpers from '../BookingHelpers/BookingHelpers';
import DateDrop from '../BookingHelpers/DateDrop/DateDrop';
import './Admin.css';
import { getDocs, getFirestore, where, collection, onSnapshot } from 'firebase/firestore';
import { doc, getDoc, deleteDoc, query } from "firebase/firestore";
import { firebaseConfig } from '../utils';
import { updateDoc } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import TableModel from '../TableModel/TableModel';
import BlockHelper from '../BookingHelpers/BlockHelper/BlockHelper';

function Admin(props) {
    const [tables, setTables] = useState([]);
    const [open, setOpen] = useState('');
    const [close, setClose] = useState('');
    const [times, setTimes] = useState([]);
    const [createHit, setCreateHit] = useState(false);
    const [reservations, setReservations] = useState([]);
    const [date, setDate] = useState(new Date().toDateString());
    const [selectedTable, setSelectedTable] = useState("");
    const [hovering, setHovering] = useState('');
    const [addingOrder, setAddingOrder] = useState(false)
    const [selectedTableNum, setSelectedTableNum] = useState();
    const [currentTime, setCurrentTime] = useState('');
    const [selectedRes, setSelectedRes] = useState('');
    const [existingOrder, setExistingOrder] = useState('');
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const [notToday, setNotToday] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState();
    const [bum, setBum] = useState(1);
    const [hoveringId, setHoveringId] = useState("");
    const [bookingsTotal, setBookingsTotal] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [newDateo, setDateo] = useState();
    const [deepRes, setDeepRes] = useState([]);
    const [feelGood, setFeelGood] = useState(0);
    const [block, setBlock] = useState(false)
    const [blocks, setBlocks] = useState([]);
    


    React.useEffect(function() {
        const app = initializeApp(firebaseConfig2, "second");
        const db = getFirestore(app);
        getDocs(collection(db, "schedule")).then(
            response => {
                let schedule = [];
                response.forEach(data => {
                    setSchedule(data.data().sched);
                })
            }
        )
    }, [])

    React.useEffect(function() {
        const app = initializeApp(firebaseConfig2, "second");
        const db = getFirestore(app);
        getDocs(collection(db, "groups")).then(
            response => {
                const groupos = [];
                response.forEach(data => {
                    groupos.push(data.data());
                })
                const tablos = [];
                for (let i = 0; i < groupos.length; i++) {
                    for (let r = 0; r < groupos[i].tables.length; r++) {
                        groupos[i].tables[r].id = groupos[i].label + groupos[i].tables[r].table_num;
                        tablos.push(groupos[i].tables[r]);
                    }
                }
                setTables(tablos)
            }
        )
    }, [])


    function deleteTable(table) {
        return async function () {
            setSelectedTable("");
            await deleteDoc(doc(db, "res", table.id));
        }
    }

   //setInterval(() => setBum(oldBum => oldBum + 1), 2000);

    // React.useEffect(function() {
        // const q = query(collection(db, "res"), where("date", "==", date));
        // let totalRes = 0;
        // for (let i = 0; i < reservations.length; i++) {
        //     totalRes = totalRes + reservations[i].length;
        // }
        // const unsubscribe = onSnapshot(q, (snapshot) => {
        //     // if (snapshot._snapshot.docChanges.length !== 1 || totalRes === 1) {
        //     //     return;
        //     // }
        //     console.log(snapshot._snapshot.docChanges.length)
        //     console.log(reservations.length)
        //     snapshot.docChanges().forEach((change) => {
        //         if (change.type === "added") {
        //             let newby = change.doc.data();
        //             let resss = [...reservations];
        //             if (!resss.length) {
        //                 return;
        //             }
        //             resss[newby.table_num - 1].push(newby);
        //          //   setReservations(resss);
        //             return;
        //         }
        //       });
        // });

        React.useEffect(function() {
            const app = initializeApp(firebaseConfig2, "second");
            const db = getFirestore(app);
            const q = query(collection(db, 'blocks'), where('date', '==', date));
            const blocksCopy = [];
            getDocs(q).then(response => {
                response.forEach(el => {
                    blocksCopy.push(el.data());
                })
                setBlocks(blocksCopy);
            })
          }, [date])


        React.useEffect(function() {
            setInterval(() => {
                setFeelGood(oldFeelGood => oldFeelGood + 1)
            }, 60000)
        }, [])

React.useEffect(function() {
       
                // let dater = "";
                // const dateArr = date.split('-');
                // if (dateArr.length > 1) {
                //     dateArr[2] = parseInt((dateArr[2])) + 1;
                //     dateArr[2] = dateArr[2].toString();
                //     dater = dateArr[0] + "-" + dateArr[1] + "-" + dateArr[2];
                // }
                // else {
                //     dater = date;
                // }
                // let datey = new Date(dater).toDateString();
                    const reservationsCopy = [];
                    const q = query(collection(db, 'res'), where('date', '==',  date));
                    getDocs(q).then(response => {
                        response.forEach(el => {
                            console.log(el.data())
                            reservationsCopy.push(el.data());
                        })
                        console.log(reservationsCopy.length, deepRes.length)
                        console.log(deepRes.length)
                        if (reservationsCopy.length !== deepRes.length) {
                            setDeepRes(reservationsCopy);
                            const newArr = [];
                            for (let i = 0; i < tables.length; i++) {
                              const tableObject = {label: tables[i].id, capacity: tables[i].capacity, bookings: []}
                              for (let t = 0; t < reservationsCopy.length; t++) {
                                if (reservationsCopy[t].table_num === tables[i].id) {
                                  tableObject.bookings.push(reservationsCopy[t]);
                                }
                              }
                              newArr.push(tableObject);
                            }
                            setReservations(newArr);
                        }
                  })
                    const timeObj = getTime(`${new Date().getHours()}: ${new Date().getMinutes()}`);
                    setCurrentTime(`${timeObj.hour}:${timeObj.minute} ${timeObj.ap}`);
}, [feelGood])        

    




    // }, [bum])

    useEffect(function () {
        const timeObj = getTime(`${new Date().getHours()}: ${new Date().getMinutes()}`);
        setCurrentTime(`${timeObj.hour}:${timeObj.minute} ${timeObj.ap}`);
    }, [])



    function getColor(startTime, confirmed) {
        if (confirmed) {
            return 'lightblue'
        }
        if (new Date().toDateString() !== date) {
            return `rgb(0,200,0,.40)`
        }
        else if (stringToIntTime[currentTime] > stringToIntTime[startTime]) {
            return `rgb(240, 0, 0, ${(stringToIntTime[currentTime] - stringToIntTime[startTime]) / 24})`
        }
        else if (stringToIntTime[currentTime] === stringToIntTime[startTime]) {
            return `lightyellow`;
        }
        else {
            return 'lightgreen'
        }
    }

    function tableHit(table, index) {
        return function () {
            console.log(table)
            setSelectedTable(table)
            setSelectedIndex(index)
        }
    }

    React.useEffect(function () {
        const timesrep = [];
        for (let i = stringToIntTime[open]; i <= stringToIntTime[close]; i++) {
            if (i % 3 === 0) {
                timesrep.push(intToStringTime[i]);
            }
        }
        setTimes(timesrep);
    }, [open, close])

    React.useEffect(function () {
        setDateo(date);
        if (tables.length) {
            console.log("HOW OFTEN DO I RUN")
            const reservationsCopy = [];
            const q = query(collection(db, 'res'), where('date', '==', date));
            getDocs(q).then(response => {
                response.forEach(el => {
                    reservationsCopy.push(el.data());
                })
                setDeepRes(reservationsCopy);
                const newArr = [];
                for (let i = 0; i < tables.length; i++) {
                    const tableObject = {label: tables[i].id, capacity: tables[i].capacity, bookings: []}
                    for (let t = 0; t < reservationsCopy.length; t++) {
                      if (reservationsCopy[t].table_num === tables[i].id) {
                        tableObject.bookings.push(reservationsCopy[t]);
                      }
                    }
                    newArr.push(tableObject);
                  }
                  setReservations(newArr)
            })
        }
    }, [date, tables.length])

    function goToOrders() {
        props.history.push('/admin/orders')
    }

    function toSetCreateHit() {
        setCreateHit(oldvalue => {
            return !oldvalue;
        })
    }

    function hi() {
        console.log('hello');
    }

    async function hello(resId) {
        const res = doc(db, "res", resId);
        await updateDoc(res, {
            confirmed: true
        })
    }

    
    React.useEffect(function () {
        if (schedule.length) {
            setOpen(schedule[new Date(date).getDay()].open)
            setClose(schedule[new Date(date).getDay()].close)
        }
        if (new Date().toDateString() !== date) {
            setNotToday(true);
        }
        else {
            setNotToday(false);
        }
    }, [date, schedule.length])

    function goToSchedule() {
        props.history.push('/businessSchedule')
    }
    
    function goToTableEditor() {
        props.history.push("/tableEditor");
    }

    function goToBusiness() {
        props.history.push('/business')
    }

    function goToMenu() {
        props.history.push('/menuCreator')
    }

    function toSetBlock() {
        setBlock(true);
    }

    function hideBlock() {
        setBlock(false);
    }




    return (
        <div id='bigDog'>         
            {(createHit || selectedTable !== "" || addingOrder || block) && <div style={{ position: 'absolute', top: 0, left: 0, height: `${(stringToIntTime[close] - stringToIntTime[open]) * 20.5 + 197}px`, width: tables.length < 12 ? "100%" : `${(tables.length + 1) * 107}px`, opacity: '80%', backgroundColor: 'black', zIndex: 803940249030 }}></div>}
            {createHit && <BookingHelpers blocks={blocks} reservations={reservations} tables={tables} hideMe={toSetCreateHit} />}
            {block && <BlockHelper hideMe={hideBlock}/> }
            {selectedTable !== "" && <TableModel selectedTable={selectedTable} deleteTable={deleteTable} hideMe={() => setSelectedTable("")} table={selectedTable} />}
            <div id={'switch'} style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: "rgb(230,230,230)", borderBottom: "1.4px solid black", paddingBottom: "10px", width: '100%' }}>
                <div style={{ marginTop: '20px' }}><DateDrop setDateString={setDate} /></div>
                <div id={'smallDiv'} style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div className="smallerDiv">
                    <button onClick={toSetBlock} style={{ height: '30px', width: '60px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Block</button>
                    <button onClick={toSetCreateHit} style={{ height: '30px', width: '60px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Create</button>
                    <button onClick={goToOrders} style={{ height: '30px', width: '60px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Orders</button>
                    <button onClick={goToMenu} style={{ height: '30px', width: '60px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Menu</button>
                    
                    </div>
                    <div className="smallerDiv">
                    <button onClick={goToTableEditor} style={{ height: '30px', width: '85px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Table Editor</button>
                    <button onClick={goToBusiness} style={{ height: '30px', width: '85px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Business</button>
                    <button onClick={goToSchedule} style={{ height: '30px', width: '70px', borderRadius: "20px", boxShadow: "0px 0px 2px black", border: "none", cursor: 'pointer', marginTop: "20px" }}>Schedule</button>
                    
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '80px', marginTop: "62px", height: `${(times.length - 1) * 60.65}px` }}>
                    {times.map(e => {
                        return <p style={{ width: "80px", paddingLeft: "10px", height: `${60.65}px` }}>{e}</p>
                    })}
                </div>
                <div style={{ display: 'flex', padding: "20px", marginTop: "20px", marginTop: '50px', position: 'relative' }}>
                    {reservations.map((e, i) => {
                        return <div style={{ padding: "20px", position: 'relative', width: '65px', paddingTop: "0px", height: `${(times.length - 1) * 60.66}px`, borderLeft: i === 0 ? '1.4px solid black' : '0.8px solid black', borderRight: i === tables.length - 1 ? '1.4px solid black' : '0.8px solid black' }}>
                            <p style={{ position: 'absolute', top: '-35px' }}>Table: {e.label}</p>
                            {e.bookings && e.bookings.map(element => {
                                const color = getColor(element.time, element.confirmed);
                                return <div onClick={!hovering ? tableHit(element, e) : () => hello(element.id)} className='table' style={{ position: "absolute", backgroundColor: color, cursor: 'pointer', borderTop: "2px solid black", borderBottom: "2px solid black", display: "flex", flexDirection: 'column', justifyContent: "space-between", alignItems: 'center', width: "105px", right: "0px", height: `${20.22 * 16}px`, top: (stringToIntTime[element.time] - stringToIntTime[open]) * 20.22}}><p>{element.time}</p><p>{intToStringTime[stringToIntTime[element.time] + 16]}</p>
                                </div>
                            })}
                        </div>
                    })}
                    {blocks.map(block => {
                        return <div style={{backgroundColor: "orange", justifyContent: "center", position: "absolute", zIndex: "243423", display: "flex",  top: `${(stringToIntTime[block.timeStart] - stringToIntTime[open]) * 20.23 + 20}px`, left: "20.5px", height: `${(stringToIntTime[block.timeEnd] - stringToIntTime[block.timeStart]) * 20.22}px`, width: `${tables.length * 106}px`}}><p style={{marginTop: "4px", fontWeight: "bold"}}>This time is blocked off from {block.timeStart} to {block.timeEnd}.</p></div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default withRouter(Admin);