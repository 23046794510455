import React from 'react';
import ShiftCreator from './ShiftCreator/ShiftCreator';
import WeekSelector from './WeekSelector/WeekSelector';
import styles from './BusinessSchedule.module.css';
import ShiftSchedule from './ShiftSchedule/ShiftSchedule';
import ShiftEditor from './ShiftEditor/ShiftEditor';
import {getDocs, getFirestore, where, collection, setDoc} from 'firebase/firestore';
import { doc, getDoc, query, deleteDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig2 } from '../utils';
import { withRouter } from 'react-router';

const app = initializeApp(firebaseConfig2, "second");
const db = getFirestore(app);

function BusinessSchedule(props) {
    const [schedule, setSchedule] = React.useState('');
    const [employees, setEmployees] = React.useState('');
    const [shifts, setShifts] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [anyNum, setAnyNum] = React.useState(1);
    const [shiftBeingEdited, setShiftBeingEdited] = React.useState("");
    const [dateChosen, setDateChosen] = React.useState(new Date().toDateString());
    const [editingShift] = React.useState(false);

    React.useEffect(function() {
        getDocs(collection(db, "employees")).then(
            response => {
                const emps = [...employees];
                response.forEach(data => {
                    emps.push(data.data());
                })
                setEmployees(emps);
            }
        )
    }, [])

    function addNewShift(shift) {
        const shiftos = [...shifts];
        shiftos.push(shift);
        setShifts(shiftos);
    }


    function getNewShifts() {
        setAnyNum(anyNum => anyNum + 1);
    }

    function goToEmployees() {
        props.history.push('/employees')
    }

    React.useEffect(() => {

        // let businessId;
        // if (props.admin) {
        //     businessId = props.admin.admin.businessId
        // }
        // else if (props.employee) {
        //     businessId = props.match.params.businessId
        // }
        // console.log(businessId)
        // axios.post('/api/business/businessschedule', {businessId})
        // .then(
        //     response => {
        //         if (response.status === ) {
        //             setSchedule(response.data.schedule)
        //             setEmployees(response.data.employees)
        //             setBookingColumnsNumber(response.data.bookingColumnNumber)
        //             setBookingColumnsType(response.data.bookingColumnsType)
        //         }
        //     }
        // )
    },[])



    React.useEffect(function() {
        const shiftos = [];
        const q = query(collection(db, 'shifts'), where('date', '==', dateChosen ));
        getDocs(q).then(response => {
            response.forEach(el => {
                shiftos.push(el.data());
            })
        setShifts(shiftos);
        })
        console.log(shiftos);
    // axios.post('api/shifts/get',
    //  {shiftDate: props.shiftDate, businessId: props.admin.admin.businessId})
    //  .then(response => {
    //      if (response.status === 204) {
    //          setShifts([])
    //          setLoading(false)
    //      }
    //      if (response.data.shifts) {
    //         setShifts(response.data.shifts)
    //         setLoading(false)
    //      }
    //  }
    // ).catch(error => {
    //     console.log(error)
    // })

}, [dateChosen]) 

function getShifts() {
//     axios.post('api/shifts/get',
//     {shiftDate: props.shiftDate, businessId: props.admin.admin.businessId})
//     .then(response => {
//         if (response.status === 204) {
//             setShifts([])
//             setLoading(false)
//         }
//         if (response.data.shifts) {
//            setShifts(response.data.shifts)
//            setLoading(false)
//         }
//     }
//    ).catch(error => {
//        console.log(error)
//    })
}

function deleteShift(shiftId, index) {
    return async function () {
           const shiftos = [...shifts].filter((e, i) => i !== index);
           console.log(shiftId)
           await deleteDoc(doc(db, "shifts", shiftId));
           setShifts(shiftos);
    }

    //     axios.post("/api/shifts/deleteOne", {shiftId}, {headers: {"x-auth-token": props.adminToken}}).then(response => {
    //         if (response.status === 200) {
    //             const newShifts = [...shifts].filter(e => e._id !== shiftId);
    //             setShifts(newShifts);
    //         }
    //       }    
    //     )
     
}

function selectEditingShift(id) {
     setShiftBeingEdited(id)
}

    return (
        <div id={styles.container}>
            {/* <button style={{position: 'absolute', right: "30px", height: "28px", width: "120px", boxShadow: '0px 0px 4px black', border: 'none', top: "20px"}} onClick={goToEmployees}>Edit Employees</button> */}
            <div id={styles.leftHolder}>
                <WeekSelector setDateChosen={setDateChosen}/>
                {editingShift && <ShiftEditor selectEditingShift={selectEditingShift} getShifts={getShifts} shiftBeingEdited={shiftBeingEdited}  schedule={schedule} admin={props.admin.admin} employees={employees}/>}
                {!editingShift && <ShiftCreator addNewShift={addNewShift} dateChosen={dateChosen}  schedule={schedule} employees={employees} getNewShifts={getNewShifts}/>}
            </div>
            <div id={styles.rightHolder}>
            <ShiftSchedule deleteShift={deleteShift} loading={loading} shiftBeingEdited={shiftBeingEdited} selectEditingShift={selectEditingShift} dateChosen={dateChosen} shifts={shifts}/>
            </div>
        </div>
    )
}


export default withRouter(BusinessSchedule);