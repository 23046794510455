import React, { useState } from 'react';
import styles from './UserTakeout.module.css';
import {firebaseConfig, firebaseConfig2} from '../../utils';
import {getFirestore, doc, setDoc, Firestore, collection, getDocs} from 'firebase/firestore';
import { initializeApp } from "firebase/app";
import {withRouter} from 'react-router-dom';

function UserTakeout(props) {
    const [order, setOrder] = useState([]);
    const [orderBrief, setOrderBrief] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [lgap, setlgap] = useState();
    const [itemCounter, setItemCounter] = useState(1);
    const [member, setMember] = useState('');
    const [success, setSuccess] = useState(false);
    const [menu, setMenu] = useState([]);
    const [additionalDetails, setAdditionalDetails] = useState([]);

    React.useEffect(function() {
        const app = initializeApp(firebaseConfig2, "second");
        const db = getFirestore(app);
        getDocs(collection(db, "menuGroups")).then(
          response => {
              const groupos = [];
              response.forEach(data => {
                  groupos.push(data.data());
              })
              setMenu(groupos);      
          }
        )
      },[]);

    function clicker(index, indexo) {
        if (index !== lgap) {
            setlgap(`${indexo} ${index}`);
           
        }
    }

    function addItem(item, index, indexo) {
        const orderCopy = [...order];
        for (let i = 0; i < itemCounter; i++) {
            orderCopy.push(item);
            console.log(parseInt(item.itemPrice * 100) / 100);
            console.log(item.itemPrice)
            setOrderTotal(oldTotal => oldTotal + parseInt(item.itemPrice * 100) / 100)
        }
        setOrder(orderCopy);
        const orderBriefCopy = [...orderBrief];
        for (let i = 0; i < itemCounter; i++) {
            orderBriefCopy.push(`${indexo} ${index}`);
        }
        setOrderBrief(orderBriefCopy);
        setItemCounter(1);
    }

    function goHome() {
        props.history.push("/ddwdwdwdwdw");
    }

    function editOrder(index, price, name) {
        const orderRep = [...order].filter((e, i) => {
            return index !== i;
        })
        setOrderTotal(oldTotal => oldTotal - price);
       
        let num;
        for (let i = 0; i < menu.length; i++) {
            if (menu[i].itemName === name) {
                num = i;
            }
        }
        const brief = [...orderBrief].filter(e => e !== num);
    
        setOrderBrief(brief);
        setOrder(orderRep);
    }

    function addAdditional() {
        setItemCounter(oldCounter => oldCounter + 1);
        setSuccess(false);
    }

    function removeAdditional() {
        setItemCounter(oldCounter => oldCounter - 1);
    }

    async function createOrder() {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
    
        const newOrderRef = doc(collection(db, "orders"));
        await setDoc(newOrderRef, {
            order: orderBrief,
            member,
            date: new Date().toDateString(),
            time: `${new Date().getHours()}:${new Date().getMinutes()}`,
            complete: false,
            id: newOrderRef.id,
            additionalDetails
          });
          setOrderBrief([]);
          setOrder([]);
          setOrderTotal(0);
          setMember('');
          setSuccess(true);
    }

    return (
        <div id={styles.main}>
            <div id={styles.heighto} style={{position: "absolute", background: "black", opacity: "70%", width: "100%"}}></div>
            <p id={styles.header} style={{color: 'rgb(222, 255, 222)', zIndex: 200}}>Restaurant Takeout Center</p>
            <div id={styles.body}>
                <div style={{zIndex: 2000}} id={styles.left}>
                    <p style={{fontWeight: "bold", textAlign: "center", paddingBottom: "12px", color: 'rgb(222, 255, 222)'}}>Restaurant Menu:</p>
                    <div id={styles.boxy} style={{borderRadius: "14px", overflow: "auto", backgroundColor: "#e5f7fd", boxShadow: "0px 0px 4px black"}}>
                        <div>
                            {menu.map((e, indexo) => {
                                return <div>
                                    <p style={{textAlign: "center", paddingTop: "4px", fontSize: "28px"}}>{e.groupLabel}</p>
                                    {e.menuItems.map((item,i) => {
                                       return <div onClick={() => clicker(i, indexo)} id={styles.bigGuy} style={{paddingTop: "30px", paddingBottom: "30px", backgroundColor: lgap === `${indexo} ${i}` ? "lightgreen" : ""}}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: "18px"}}>  <p style={{paddingLeft: "20px", fontWeight: "bold"}}>{item.itemName}</p> <p style={{paddingRight: "20px"}}>${item.itemPrice}</p> </div>
                                        <p style={{fontSize: "15px", paddingTop: "7px", paddingLeft: "20px", width: "250px"}}>{item.itemDescription}</p>
                                        {`${indexo} ${i}` === lgap && <div style={{display: "flex", paddingTop: "20px", justifyContent: "center"}}><button style={{height: "20px", width: "20px", borderRadius: "50%", boxShadow: "0px 0px 3px black", border: "none", cursor: "pointer"}} onClick={removeAdditional}>-</button><p style={{paddingLeft: "10px", paddingRight: "10px"}}>Item Count: {itemCounter}</p><button style={{height: "20px", width: "20px", borderRadius: "50%", boxShadow: "0px 0px 3px black", border: "none", cursor: "pointer"}} onClick={addAdditional}>+</button><button style={{marginLeft: "40px", border: "none", boxShadow: "0px 0px 4px black", height: "25px", width: "75px", cursor: "pointer"}} onClick={() => addItem(item,i, indexo)}>{lgap === orderBrief[orderBrief.length - 1] ? "Add Again" : "Add Item"}</button></div>}
                                    </div>
                                    })}
                                    </div>
                                })}
                        </div>
                    </div>
                </div>
                <div style={{zIndex: 2000}} id={styles.right}>
                    <p style={{fontWeight: "bold", textAlign: "center", paddingBottom: "12px", color: 'rgb(222, 255, 222)'}}>Your Order:</p>
                    <div id={styles.boxy} style={{ borderRadius: "14px", overflow: "auto", backgroundColor: "#e5f7fd", boxShadow: "0px 0px 4px black"}}>
                    {order.length === 0 && !success && <p style={{paddingTop: '10px', textAlign: 'center'}}>Theres nothing in your order cart right now.</p>}
                    {order.length === 0 && success && <p style={{paddingTop: '20px', lineHeight: "22px", fontSize: "16px", paddingLeft: "10px", paddingRight: "10px"}}>Your order has been submitted. Use your member number to check your order status on the mfceat home page.</p>}
                    {order.length === 0 && success && <button onClick={goHome} style={{border: "none", boxShadow: "0px 0px 4px black", height: "24px", width: "100px", marginLeft: "120px", marginTop: "40px", fontSize: "16px", paddingLeft: "10px", paddingRight: "10px", textAlign: 'center'}}>Home</button>}

                        {order.map((e,i) => {
                            return  <div style={{ display: 'flex', borderBottom: "0.3px solid black", paddingTop: "15px", paddingBottom: "15px", justifyContent: 'space-between', fontSize: "18px"}}>  <p style={{paddingLeft: "20px",fontWeight: "bold"}}>{e.itemName}</p> <div style={{display: 'flex'}}><p style={{paddingRight: "10px", paddingTop: '3px'}}>${e.itemPrice}</p><button style={{fontWeight: 'bold', marginRight: "10px", fontSize: "16px", borderRadius: "50%", height: "30px", width: "30px", paddingBottom: "30px", backgroundColor: "rgb(240,0,0,.50)", boxShadow: "0px 0px 4px black", border: 'none', cursor: 'pointer'}} onClick={() => editOrder(i, e.itemPrice, e.itemName)}>_</button></div> </div>
                        })}
                        {orderTotal !== 0 && <p style={{marginLeft: "20px", marginTop: "20px"}}>Additional Order Requests: </p>}
                        {orderTotal !== 0 && <textarea value={additionalDetails} onChange={(e) => setAdditionalDetails(e.target.value)} placeholder='Extra Order Details' style={{resize: "none", paddingTop: "4px", paddingLeft: "4px", marginTop: "10px", border: "none", boxShadow: "0px 0px 4px black", height: "70px", width: "300px", marginLeft: "20px"}} />}
                        {orderTotal !== 0 && <div style={{ display: 'flex', paddingTop: "25px", paddingBottom: "25px", justifyContent: 'space-between', fontSize: "16px"}}>  <p style={{paddingLeft: "20px", fontWeight: 'bold'}}>Your Order Total:</p> <p style={{paddingRight: "20px", fontWeight: 'bold'}}>${orderTotal}</p> </div>}
                        {orderTotal !== 0 && <div style={{paddingTop: "20px", paddingBottom: "20px", display: 'flex', justifyContent: 'space-around'}}><input value={member} onChange={(e) => setMember(e.target.value)} style={{height: "30px", paddingLeft: "4px", border: "none", boxShadow: "0px 0px 4px black"}} placeholder='Enter Member Number'/>
                            <button style={{border: "none", width: "120px", cursor: 'pointer', marginTop: "0px", height: "30px", backgroundColor: "white", boxShadow: "0px 0px 3px black"}} onClick={createOrder}>Confirm Order</button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(UserTakeout);