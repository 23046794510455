import React, {useState, useEffect} from "react";
import TimeList from '../TimeList/TimeList';
import DateDrop from "../DateDrop/DateDrop";
// import ColorButton from '../../../Shared/ColorButton/ColorButton';
import SubmitButton from '../SubmitButton/SubmitButton';
import {intToStringTime, createMaplist, getTimeRightAway, stringToIntTime, getTime} from '../../utils';
// import OtherAlert from '../../../OtherAlerts/OtherAlerts';
// import BCAList from "../../../Shared/BCAList/BCAList";
// import x from './x.png'
import {withRouter} from 'react-router-dom';
import styles from './BlockHelper.module.css';
import {getFirestore, doc, setDoc, where, Firestore, getDocs, collection} from 'firebase/firestore';
import { getDoc, query } from "firebase/firestore";
import {firebaseConfig, firebaseConfig2} from '../../utils';
import { initializeApp } from 'firebase/app';

const app = initializeApp(firebaseConfig2, "second");
const db = getFirestore(app);


  function BlockHelper(props) {
    const [timeStart, setTimeStart] = useState();
    const [timeEnd, setTimeEnd] = useState();
    const [times, setTimes] = useState([]);
    const [date, setDate] = useState(new Date().toDateString())
    const [schedule, setSchedule] = useState([])


    React.useEffect(function() {
      getDocs(collection(db, "schedule")).then(
          response => {
              let sched = [];
              response.forEach(data => {
                  //setSchedId(data.data().id)
                  setSchedule(data.data().sched);
                  console.log(data.data().sched)
              })
          }
      )
  }, [])

  function toSetTimeStart(timeStart) {
    setTimeStart(timeStart);
  }

  function toSetTimeEnd(timeEnd) {
    setTimeEnd(timeEnd);
  }
 



    useEffect(function() {
      if (schedule.length) {
        let dater = new Date();
        if (date !== "") {
            dater = new Date(date);
        }
        const weekDayNum = dater.getDay();
        const newTimes = [];
       let i = stringToIntTime[schedule[weekDayNum].open];
        
        let timeRightAway = "";
        while (i <= stringToIntTime[schedule[weekDayNum].close]) {
            console.log(i);
            if (intToStringTime[i] === getTimeRightAway()) {
                timeRightAway = intToStringTime[i];
            }
            newTimes.push(i);
            i++;
        }
        timeRightAway === "" ? setTimeStart(schedule[weekDayNum].open) : setTimeStart(timeRightAway); 
        timeRightAway === "" ? setTimeEnd(schedule[weekDayNum].open) : setTimeEnd(timeRightAway); 
        setTimes(newTimes);
      }
 
     }, [date, schedule.length])

    //appear={employeesBack.length > 0 && (bcnArray === undefined || (bcnArray && bcnArray.length > 0))}

    async function booko() {
        const newBlockRef = doc(collection(db, "blocks"));
        await setDoc(newBlockRef, {
          timeStart,
          timeEnd,
          date: date,
          id: newBlockRef.id
        });
        props.hideMe()
      }


    return (
      <div id={styles.bookingHolder} style={{position: 'absolute', border: '2px solid black', left: window.innerWidth / 3, top: 50, zIndex: 900900000900000}}>
        <p style={{position: 'absolute', top: '0px',  right: '20px', fontSize: "36px"}}>x</p>
          <div id={styles.newContainer}>
              <div className={styles.holder}>
              <p onClick={props.hideMe} style={{fontSize: "18px", cursor: 'pointer', fontWeight: 'bold', marginTop: "20px", textAlign: "center", position: 'relative', right: "20px"}}>Create Block:</p>
              <div style={{marginTop: "32px"}}>
                <p className={styles.ptags}>Select Date:</p>
                <DateDrop small={true} setDateString={setDate}/>
              </div>
              <div style={{marginTop: "26px"}}>
                   <p className={styles.ptags}>Select Time Start:</p>
                  <TimeList time={timeStart} times={times} setTime={(time) => toSetTimeStart(time)}/>
              </div>
              <div style={{marginTop: "26px"}}>
                   <p className={styles.ptags}>Select Time End:</p>
                  <TimeList time={timeEnd} times={times} setTime={(time) => toSetTimeEnd(time)}/>
              </div>
              </div>
              <div style={{display: "flex", flexDirection: 'column',}}>
              <div style={{display: 'flex'}}>
            </div>
            <button onClick={booko} style={{height: '40px', width: "150px", alignSelf: "center", border: "none", boxShadow: "0px 0px 4px black", cursor: "pointer", marginBottom: "20px", marginTop: "30px"}}>Create Block</button>
              </div>
        </div>
        {/* <OtherAlert showAlert={successMessage !== ""} alertMessage={successMessage} alertType={"success"}/> */}
        {/* <OtherAlert showAlert={error !== ""} alertMessage={error} alertType={"notgood"}/> */}
      </div>
    );
  }






export default BlockHelper;
