import React, { useState } from 'react';
import { firebaseConfig2, menu } from '../../utils';
import { withRouter } from 'react-router';
import {getDocs, getFirestore, where, collection, setDoc} from 'firebase/firestore';
import { doc, getDoc, query, deleteDoc, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const app = initializeApp(firebaseConfig2, "second");
const db = getFirestore(app);

export default function MenuCreator() {
    const [groups, setGroups] = useState([]);
    const [creatingNewGroup, setCreatingNewGroup] = useState(false);
    const [groupLabel, setGroupLabel] = useState("");
    const [itemName, setItemName] = useState("");
    const [hitIndex, setHitIndex] = useState(-1);
    const [itemPrice, setItemPrice] = useState("");
    const [table_num, setTableNum] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [usli, setusli] = useState(-1);
    const [prefo, setPrefo] = useState("");
    

        React.useEffect(function() {
            getDocs(collection(db, "menuGroups")).then(
                response => {
                    const groupos = [...groups];
                    response.forEach(data => {
                        groupos.push(data.data());
                    })
                    setGroups(groupos);
                }
            )
        }, [])

        React.useEffect(function() {
         if (usli !== -1) {
            console.log(usli)
            if (groups[usli].seatingLocation) {
                setPrefo(groups[usli].seatingLocation);
            }
            else {
                setPrefo("Seating Location");
            }
         }
        }, [usli]) 


       async function deleteMenuItem(groupIndex, itemIndex, groupId) {
        console.log(groupId);
        console.log(groupIndex)
        const newGroupos = [...groups];
        console.log(itemIndex)
        const newItems = newGroupos[groupIndex].menuItems.filter((e, i) => {
            return i !== itemIndex;
        })
        newGroupos[groupIndex].menuItems = newItems;
        console.log(newItems);
        setGroups(newGroupos);
            const groupRef = doc(db, "menuGroups", groupId);
            await updateDoc(groupRef, {
                menuItems: newItems
            });
        }

        function toSetCreatingNewGroup() {
            setCreatingNewGroup(true);
        }

       async function createNewMenuItem(groupId) {
            const menuObj = {itemName, itemPrice, itemDescription};
            const groupos = [...groups];
            groupos[hitIndex].menuItems.push(menuObj);
            const newMenuItems = groupos[hitIndex].menuItems;
            setGroups(groupos);
            setHitIndex(-1);
            const groupRef = doc(db, "menuGroups", groupId);
            await updateDoc(groupRef, {
                menuItems: newMenuItems
            });

        }

       async function createNewGroup() {
            const ref = doc(collection(db, 'menuGroups'));
            const groupos = [...groups];
            const newGroup = {groupLabel, menuItems: [], id: ref.id};
            groupos.push(newGroup);
            setGroups(groupos);
            setCreatingNewGroup(false);
            setGroupLabel("");
            await setDoc(ref, {...newGroup, id: ref.id});
        }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-around", marginTop: "20px"}}>
            <p style={{textAlign: "center", fontWeight: "bold", fontSize: "20px"}}>Menu Creator</p>
            {creatingNewGroup && <div>
                    <input value={groupLabel} onChange={(e) => setGroupLabel(e.target.value)} style={{border: "none", boxShadow: "0px 0px 4px black", padding: "4px", fontWeight: "bold", width: "140px"}} placeholder='Menu Group Name'/>
                    <button onClick={createNewGroup} style={{padding: "4px", height: "28px", boxShadow: "0px 0px 4px black", border: 'none', cursor: 'pointer', marginLeft: "15px"}}>Create</button>
                    </div>}
                {!creatingNewGroup && <button onClick={toSetCreatingNewGroup} style={{border: "none", boxShadow: "0px 0px 4px black", padding: "2px 4px", cursor: "pointer", fontSize: "18px", height: "28px"}}>Create New Menu Group</button>}
            </div>
            <div style={{ marginTop: "70px", maxWidth: "100%", overflow: "auto", padding: "20px", display: "block ruby"}}>
                {groups.map((group, index) => {
                   return <div style={{width: "300px", marginLeft: "40px", maxHeight: "600px", overflow: "auto", boxShadow: "0px 0px 4px black", paddingTop: "6px", height: "590px", paddingBottom: "10px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <p style={{textAlign: "center", fontWeight: "bold", paddingBottom: "20px"}}>Menu Group: {group.groupLabel}</p>     
                        {group.menuItems.map((menuItem,i) => {
                            return <div style={{padding: "6px", width: "240px", boxShadow: "0px 0px 4px black", marginTop: "20px"}}>
                                        <p style={{marginTop: "2px", marginLeft: "2px"}}>{menuItem.itemName}</p>
                                        <p style={{marginTop: "6px", marginLeft: "2px"}}>${menuItem.itemPrice}</p>
                                        <p style={{marginTop: "6px", marginLeft: "2px"}}>{menuItem.itemDescription}</p>
                                        <button style={{marginLeft: "70px", marginTop: "16px", padding: "4px", width: "100px", border: "none", boxShadow: "0px 0px 4px black", backgroundColor: "rgb(220, 0, 0, 0.4)", cursor: "pointer"}} onDoubleClick={() => deleteMenuItem(index, i, group.id)}>Delete Item</button>
                                    </div>
                            })}
                        {hitIndex === index && <input onChange={(e) => setItemName(e.target.value) } placeholder={"Item Name"} style={{padding: "4px", marginTop: "40px", border: "none", width: "130px", boxShadow: "0px 0px 4px black", backgroundColor: "white"}}/>}
                        {hitIndex === index && <div style={{display: 'flex', height: "24px", marginLeft: "-12px", marginTop: "24px"}}><p>$</p><input onChange={(e) => setItemPrice(e.target.value) } placeholder={"Item Price"} style={{padding: "4px", marginLeft: "2px", border: "none", boxShadow: "0px 0px 4px black", width: "130px", backgroundColor: "white"}}/></div>}
                        {hitIndex === index && <textarea onChange={(e) => setItemDescription(e.target.value) } placeholder={"Item Description"} style={{padding: "4px", height: "140px", width: "200px", resize: "none", marginTop: "24px", border: "none", boxShadow: "0px 0px 4px black", backgroundColor: "white"}}/>}
                        
                        {hitIndex !== index && <button style={{padding: "4px", marginTop: "20px", height: "28px", boxShadow: "0px 0px 4px black", marginTop: "20px", border: 'none', cursor: 'pointer'}} onClick={() => setHitIndex(index)}>Create Menu Item</button>}
                        {hitIndex === index && <button style={{padding: "4px", marginTop: "20px", height: "28px", boxShadow: "0px 0px 4px black", border: 'none', cursor: 'pointer'}} onClick={() => createNewMenuItem(group.id)}>Finish Menu Item</button>}
                    </div>
                })}

            </div>
        </div>
    )
};
