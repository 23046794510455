import React from 'react';
import styles from './SelectOneList.module.css';

function SelectOneList(props) {
    return (
        props.array ?
        <div style={{maxHeight: "130px", overflow: "auto", boxShadow: '0px 0px 4px #black', width: "300px", display: 'flex', flexDirection: "column", alignItems: "center", paddingBottom: "10px"}}>
        {props.array.map((element, index) => {
            return (
                    props.selected !== element.name ?
                    <div key={element["_id"]} onClick={() => props.selectOne(element.name)} id={styles.subContainer}>
                       <p style={{fontSize: "18px"}}>{element.name}</p>
                    </div>
                      :
                    <div style={{backgroundColor:"lightgray", color: "black", borderTop: "0.5px solid black", borderBottom: "0.5px solid black"}} key={element["name"]} onClick={props.unSelectOne} id={styles.subContainer}>
                       <p style={{fontSize: "18px"}}>{element.name}</p>
                    </div> 
            )
        })}
        </div> : 
        <p style={{fontWeight: "bold", textAlign: "center"}}>{props.none}</p>
    )
}

export default SelectOneList;