import User from './User/User';
import { Route, Redirect, Switch} from "react-router-dom";
import Admin from './Admin/Admin';
import React from 'react';
import UserTakeout from './User/UserTakeout/UserTakeout';
import AdminOrders from './Admin/AdminOrders/AdminOrders';
import BusinessSchedule from './BusinessSchedule/BusinesSchedule';
import EditEmployees from './EditEmployees/EditEmployees';
import TableCreator from './Admin/TableCreator/TableCreator';
import MenuCreator from './Admin/MenuCreator/MenuCreator';


function App() {
  return (
    <Switch>
        <Route path="/" exact component={User}></Route>
        <Route path="/admin" exact component={Admin}></Route>
        <Route path="/menuCreator" exact component={MenuCreator}></Route>
        <Route path="/tableEditor" exact component={TableCreator}></Route>
        <Route path="/admin/orders" exact component={AdminOrders}></Route>
        <Route path="/takeout" exact component={UserTakeout}></Route>
        <Route path="/businessSchedule" exact component={BusinessSchedule}></Route>
        <Route path="/business" exact component={EditEmployees}></Route>
        <Redirect from='*' to={'/'}/>
    </Switch>
  )
}

export default App;