import React, { useState } from 'react';
import styles from './AdminOrders.module.css';
import {getDocs, getFirestore, where, collection} from 'firebase/firestore';
import { doc, getDoc, query } from "firebase/firestore";
import {firebaseConfig, getTime, firebaseConfig2, stringToIntTime} from '../../utils';
import { initializeApp } from 'firebase/app';
import { updateDoc } from "firebase/firestore";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// const washingtonRef = doc(db, "cities", "DC");

// // Set the "capital" field of the city 'DC'
// await updateDoc(washingtonRef, {
//   capital: true
// });





function AdminOrders() {
    const [currentOrders, setCurrentOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [currentTime, setCurrentTime] = useState('');
    const [menu, setMenu] = useState([]);
    const [gotMenu, setGotMenu] = useState(false);
    const [feelGood, setFeelGood] = useState(0);

    React.useEffect(function() {
        setInterval(() => {
            setFeelGood(oldFeelGood => oldFeelGood + 1)
        }, 6000)
    }, [])



    React.useEffect(function() {
        const res = getTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
        const currento = res.hour + ":" + res.minute + " " + res.ap;
        setCurrentTime(currento);
    }, [])

   async function completeOrder(index, id) {
    console.log(index, id)
        const oldCompleted = [...completedOrders];
        oldCompleted.push(currentOrders[index]);
        setCompletedOrders(oldCompleted);
        const oldCurrent = [...currentOrders].filter((e,i) => i !== index);
        setCurrentOrders(oldCurrent);
        
        const completeRef = doc(db, "orders", id);
        await updateDoc(completeRef, {
            complete: true
        })
    }

    React.useEffect(function() {
        const app = initializeApp(firebaseConfig2, "second");
        const db = getFirestore(app);
        getDocs(collection(db, "menuGroups")).then(
          response => {
              const groupos = [];
              response.forEach(data => {
                  groupos.push(data.data());
              })
              setMenu(groupos);  
              setGotMenu(true);    
          }
        )
      },[]);


    React.useEffect(function() {
        const ordersCopy = [];
        const q = query(collection(db, 'orders'), where('date', '==', new Date().toDateString()));
        getDocs(q).then(response => {
            response.forEach(el => {
                ordersCopy.push(el.data());
            })
            const currentCopy = [];
            const completedCopy = [];
            for (let i = 0; i < ordersCopy.length; i++) {
                if (ordersCopy[i].complete) {
                    completedCopy.push(ordersCopy[i]);
                }
                else {
                    currentCopy.push(ordersCopy[i]);
                }
            }
            setCompletedOrders(completedCopy);
            setCurrentOrders(currentCopy);
        })
    }, [feelGood])




    return (
        <div>
            <p style={{textAlign: "center", fontSize: "32px", fontWeight: 'bold'}}>Orders</p>
            {gotMenu &&<div id={styles.main}>
                <div>
                    <p style={{fontSize: "24px", fontWeight: "bold", textAlign: "center", paddingBottom: "20px"}}>Current Orders</p>
                    <div>
                        {currentOrders.map((order,i) => {
                            const time = getTime(order.time);
                            const orderTime = `${time.hour}:${time.minute} ${time.ap}`;
                            return <div style={{paddingBottom: "50px", display: 'flex', flexDirection: 'column'}}>
                             <div style={{boxShadow: '0px 0px 4px black', paddingLeft: "5px", paddingRight: "5px", backgroundColor: `rgb(255,0,0,${(stringToIntTime[currentTime] - stringToIntTime[orderTime]) / 24})`, position: 'relative', display: 'flex', justifyContent: "space-between", borderBottom: "1px solid black", width: "380px"}}>
                                <div style={{display: 'flex', justifyContent: "space-around", flexDirection: 'column'}}>
                                {order.order.map((item,dex)  => {
                                    const splitItem = item.split(" ");
                                    return <p style={{marginTop: dex !== 0 ? "8px": "2px", fontWeight: "bold"}}>{menu[parseInt(splitItem[0])].menuItems[parseInt(splitItem[1])].itemName}</p>
                                })}
                                <p style={{marginTop: "50px", marginBottom: "8px"}}>Additional Requests:</p>
                                <p style={{width: "265px", paddingBottom: "6px"}}> {order.additionalDetails}</p>
                                </div>
                                <p style={{ position: 'absolute', top: '0px', right: '3px', cursor: 'pointer'}}>{orderTime}</p>
                                <p style={{ position: 'absolute', bottom: '6px', right: '3px', cursor: 'pointer'}}>{`Takeout: ${order.member}` }</p>
                            </div>
                            <button style={{height: "30px", border: "none", padding: '6px', boxShadow: "0px 0px 4px black", cursor: 'pointer', fontWeight: 'bold'}} onClick={() => completeOrder(i, order.id)}>Mark Order Complete</button>
                            </div>
                        })}
                    </div>
                </div>
                <div>
                    <p style={{fontSize: "24px", fontWeight: "bold", paddingBottom: "20px", textAlign: "center"}}>Completed Orders</p>
                    <div>
                    {completedOrders.map((order,i) => {
                            const time = getTime(order.time);
                            const orderTime = `${time.hour}:${time.minute} ${time.ap}`;
                            return <div style={{boxShadow: "0px 0px 4px black", marginTop: "15px", paddingLeft: "5px", backgroundColor: `lightgreen`, position: 'relative', display: 'flex', justifyContent: "space-between", borderBottom: "1px solid black", width: "360px"}}>
                                <div style={{display: 'flex', justifyContent: "space-between", flexDirection: 'column'}}>
                                {order.order.map(item  => {
                                    const splitItem = item.split(" ");
                                    return <p style={{fontWeight: "bold"}}>{menu[parseInt(splitItem[0])].menuItems[parseInt(splitItem[1])].itemName}</p>
                                })}
                                <p style={{paddingBottom: "8px", marginTop: "50px"}}>Additional Requests:</p> 
                                <p style={{width: "265px", paddingBottom: "6px"}}>{order.additionalDetails}</p>
                                </div>
                                <p style={{ position: 'absolute', top: '0px', right: '1px', cursor: 'pointer'}}>{orderTime}</p>
                            </div>
                        })}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default AdminOrders;