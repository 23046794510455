import React, {useState, useEffect} from 'react';
import styles from './EditEmployees.module.css';
import {getDocs, getFirestore, where, collection, setDoc, updateDoc} from 'firebase/firestore';
import { doc, getDoc, query, deleteDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import {  firebaseConfig2, intToStringTime } from '../utils';
import { withRouter } from 'react-router';

const app = initializeApp(firebaseConfig2, "second");
const db = getFirestore(app);

function EditEmployees(props) {
    const [employeeName, setEmployeeName] = useState("");
    const [employees, setEmployees] = useState([]);
    const [confirmNumber, setConfirmNumber] = useState(-1);
    const [times, setTimes] = useState([]);
    const [sunOpen, setSunOpen] = useState("");
    const [sunClose, setSunClose] = useState("");
    const [monOpen, setMonOpen] = useState("");
    const [monClose, setMonClose] = useState("");
    const [tueOpen, setTueOpen] = useState("");
    const [tueClose, setTueClose] = useState("");
    const [wedOpen, setWedOpen] = useState("");
    const [wedClose, setWedClose] = useState("");
    const [thuOpen, setThuOpen] = useState("");
    const [thuClose, setThuClose] = useState("");
    const [friOpen, setFriOpen] = useState("");
    const [friClose, setFriClose] = useState("");
    const [satOpen, setSatOpen] = useState("");
    const [satClose, setSatClose] = useState("");
    const [schedId, setSchedId] = useState("");



    React.useEffect(function() {
        const faker = [];
        for (let i = 0; i < 288; i++) {
            if (i % 3 === 0) {
                faker.push(intToStringTime[i])
            }
        }
        setTimes(faker)
    }, [])


    React.useEffect(function() {
        getDocs(collection(db, "employees")).then(
            response => {
                const emps = [];
                response.forEach(data => {
                    emps.push(data.data());
                })
                setEmployees(emps);
            }
        )
    }, [])

    React.useEffect(function() {
        getDocs(collection(db, "schedule")).then(
            response => {
                let sched = [];
                response.forEach(data => {
                    setSchedId(data.data().id)
                    sched = data.data().sched;
                })
                setSunOpen(sched[0].open);
                setSunClose(sched[0].close)
                setMonOpen(sched[1].open);
                setMonClose(sched[1].close)
                setTueOpen(sched[2].open);
                setTueClose(sched[2].close)
                setWedOpen(sched[3].open);
                setWedClose(sched[3].close)
                setThuOpen(sched[4].open);
                setThuClose(sched[4].close)
                setFriOpen(sched[5].open);
                setFriClose(sched[5].close)
                setSatOpen(sched[6].open);
                setSatClose(sched[6].close);
            }
        )
    }, [])

    async function updateSchedule() {
        const schedule = [
            {open: sunOpen, close: sunClose},
            {open: monOpen, close: monClose},
            {open: tueOpen, close: tueClose},
            {open: wedOpen, close: wedClose},
            {open: thuOpen, close: thuClose},
            {open: friOpen, close: friClose},
            {open: satOpen, close: satClose},
        ]
        const schedRef = doc(db, "schedule", schedId);
        await updateDoc(schedRef, {
            sched: schedule
        });
    }

   

   async function createEmployee() {
        if (employeeName === "") {
            return;
        }
        const ref = doc(collection(db, 'employees'));
        const emps = [...employees];
        const newEmp = {id: ref.id, name: employeeName};
        emps.push(newEmp);
        await setDoc(ref, {
            id: ref.id,
            name: employeeName
        });
        setEmployees(emps);
    }

 

    function xSlashDelete(index, employeeId) {
        return async function () {
            if (confirmNumber === index) {
               const employeesCopy = [...employees].filter((e, i) => i !== index);
               await deleteDoc(doc(db, "employees", employeeId));
               setEmployees(employeesCopy);
               setConfirmNumber(-1);
            }
            else {
                setConfirmNumber(index);
            }
        }
    }

    return (
    <div id={styles.mainDog}>
        <div id={styles.createEmployees}>
          <p style={{fontWeight: "bold", textAlign: "center", paddingTop: "10px", fontSize: "26px"}}>Add/Edit Employees</p>
            <div style={{display: "flex", width: "375px", justifyContent: "space-around", marginTop: "30px"}}>
            <input onChange={(e) => setEmployeeName(e.target.value)} value={employeeName} style={{height: "30px", width: "220px", paddingLeft: "6px", fontSize: "16px"}}/><button onClick={createEmployee} id={styles.hova} style={{width: "120px", border: "none", boxShadow: "0px 0px 5px black", cursor: "pointer"}}>Create Employee</button>
            </div>
        </div>
        <div style={{marginTop: "30px"}}>
            {employees.map((employee, index) => {
                return <div style={{boxShadow: "0px 0px 4px black", display: 'flex', justifyContent: 'space-between', marginTop: "12px", paddingLeft: "6px", width: "350px", height: "24px", paddingTop: "4px", paddingBottom: "4px", fontSize: "20px"}}>
                    <p>{employee.name}</p>
                    <p style={{paddingRight: '10px', marginTop: index === confirmNumber ? "3px" : "",  fontFamily: confirmNumber === index ? "inherit" : 'monospace', fontWeight: 'bold', fontSize: confirmNumber === index ? "16px" : '20px', cursor: 'pointer', color: confirmNumber === index ? "rgb(240, 0, 0, 0.8" : "black"}} onClick={xSlashDelete(index, employee.id)}>{confirmNumber === index ? "Confirm Delete" : "X"}</p>
                </div>
            })}
        </div>
        <div style={{marginTop: "120px", flexDirection: "column", alignItems: "center", display: "flex", borderTop: "1px solid black", width: "100%", paddingBottom: "20px"}}>
           <p style={{fontWeight: "bold", fontSize: "26px", marginTop: "15px"}}>Business Schedule</p>
           <div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Sunday: </p><select value={sunOpen} onChange={(e) => setSunOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={sunClose} onChange={(e) => setSunClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Monday: </p><select value={monOpen} onChange={(e) => setMonOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={monClose} onChange={(e) => setMonClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Tuesday: </p><select value={tueOpen} onChange={(e) => setTueOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={tueClose} onChange={(e) => setTueClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Wednesday: </p><select value={wedOpen} onChange={(e) => setWedOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={wedClose} onChange={(e) => setWedClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Thursday: </p><select value={thuOpen} onChange={(e) => setThuOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={thuClose} onChange={(e) => setThuClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Friday: </p><select value={friOpen} onChange={(e) => setFriOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={friClose} onChange={(e) => setFriClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <div style={{display: "flex", marginTop: "40px"}}><p>Saturday: </p><select value={satOpen} onChange={(e) => setSatOpen(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select><p style={{marginLeft: "10px", marginRight: "4px", fontWeight: "bold"}}> - </p><select value={satClose} onChange={(e) => setSatClose(e.target.value)} style={{boxShadow: "0px 0px 3px black", border: "none", padding: "4px", marginLeft: "10px"}}>{times.map(element => {
            return <option>{element}</option>
           })}</select></div>
           <button onClick={updateSchedule} style={{marginTop: "30px", marginLeft: "90px", border: "none", padding: "6px", boxShadow: "0px 0px 4px black", cursor: "pointer"}}>Update Schedule</button>
           </div>
        </div>
    </div>
    )
}

export default withRouter(EditEmployees);