import React, { useState } from 'react';
import styles from './TableCreator.module.css';
import { firebaseConfig2 } from '../../utils';
import { withRouter } from 'react-router';
import {getDocs, getFirestore, where, collection, setDoc} from 'firebase/firestore';
import { doc, getDoc, query, deleteDoc, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const app = initializeApp(firebaseConfig2, "second");
const db = getFirestore(app);

export default function TableCreator() {
    const [groups, setGroups] = useState([]);
    const [tables, setTables] = useState([]);
    const [creatingNewGroup, setCreatingNewGroup] = useState(false);
    const [label, setLabel] = useState("");
    const [hitIndex, setHitIndex] = useState(-1);
    const [capacity, setCapacity] = useState("");
    const [table_num, setTableNum] = useState("");
    const [seatPref, setSeatPref] = useState("");
    const [usli, setusli] = useState(-1);
    const [prefo, setPrefo] = useState("");
    

        React.useEffect(function() {
            getDocs(collection(db, "groups")).then(
                response => {
                    const groupos = [...groups];
                    response.forEach(data => {
                        groupos.push(data.data());
                    })
                    setGroups(groupos);
                }
            )
        }, [])

        React.useEffect(function() {
         if (usli !== -1) {
            console.log(usli)
            if (groups[usli].seatingLocation) {
                setPrefo(groups[usli].seatingLocation);
            }
            else {
                setPrefo("Seating Location");
            }
         }
        }, [usli]) 

        async function seatingLocationStuff(groupId) {
            if (prefo !== "" && prefo !== "Seating Location") {
                const groupRef = doc(db, "groups", groupId);
                await updateDoc(groupRef, {
                    seatingLocation: prefo
                });
                const groupos = [...groups];
                groupos[usli].seatingLocation = prefo;
                setGroups(groupos);
                setusli(-1)
            }
        }

       async function deleteTable(groupIndex, tableIndex, groupId) {
        const newGroupos = [...groups];
        const newTablos = newGroupos[groupIndex].tables.filter((e, i) => {
            return i !== tableIndex;
        })
        console.log(newTablos);
        
        newGroupos[groupIndex].tables = newTablos;
        setGroups(newGroupos);
            const groupRef = doc(db, "groups", groupId);
            await updateDoc(groupRef, {
                tables: newTablos
            });
        }

        function toSetCreatingNewGroup() {
            setCreatingNewGroup(true);
        }

       async function createNewTable(groupId) {
            const tabObj = {capacity, table_num};
            const groupos = [...groups];
            groupos[hitIndex].tables.push(tabObj);
            const newTablos = groupos[hitIndex].tables;
            setGroups(groupos);
            setHitIndex(-1);

            const groupRef = doc(db, "groups", groupId);


            await updateDoc(groupRef, {
                tables: newTablos
            });

        }


       async function createNewGroup() {
            const ref = doc(collection(db, 'groups'));
            const groupos = [...groups];
            const newGroup = {label, tables: [], id: ref.id};
            groupos.push(newGroup);
            setGroups(groupos);
            setCreatingNewGroup(false);
            setLabel("");
            await setDoc(ref, {...newGroup, id: ref.id});
        }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-around", marginTop: "20px"}}>
            <p style={{textAlign: "center", fontWeight: "bold", fontSize: "20px"}}>Restaurant Table Creator</p>
            {creatingNewGroup && <div>
                    <input value={label} onChange={(e) => setLabel(e.target.value)} style={{border: "none", boxShadow: "0px 0px 4px black", padding: "4px", fontWeight: "bold", width: "100px"}} placeholder='Identifier'/>
                    <button onClick={createNewGroup} style={{padding: "4px", height: "28px", boxShadow: "0px 0px 4px black", border: 'none', cursor: 'pointer', marginLeft: "15px"}}>Create</button>
                    </div>}
                {!creatingNewGroup && <button onClick={toSetCreatingNewGroup} style={{border: "none", boxShadow: "0px 0px 4px black", padding: "2px 4px", cursor: "pointer", fontSize: "18px", height: "28px"}}>Create New Table Group</button>}
            </div>
            <div style={{ marginTop: "70px", maxWidth: "100%", overflow: "auto", padding: "20px", display: "block ruby"}}>
                {groups.map((group, index) => {
                   return <div style={{width: "300px", marginLeft: "40px", maxHeight: "600px", overflow: "auto", boxShadow: "0px 0px 4px black", paddingTop: "6px", height: "600px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <p style={{textAlign: "center", fontWeight: "bold"}}>Table Group: {group.label}</p>
                        <div style={{display: "flex", width: "100%", borderBottom: "1px solid gray", paddingBottom: "20px", justifyContent: "space-around", marginTop: "20px", height: "60px"}}>
                        {usli === index && <select value={prefo} onChange={(e) => setPrefo(e.target.value)} style={{fontSize: '18px', cursor: "pointer", marginTop: "4px", paddingLeft: "6px", boxShadow: "0px 0px 4px black", height: "30px", border: "none"}}>
                            <option>Seating Location</option>
                            <option>Sunroom</option>
                            <option>Patio</option>
                            <option>Inside</option>
                        </select>}
                        
                        {usli === index && 
                        <button style={{boxShadow: "0px 0px 4px black", marginTop: "4px", height: "30px", border: "none", cursor: "pointer", padding: "4px"}} onClick={() => seatingLocationStuff(group.id)}>Choose</button> }
                        {usli !== index && <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                         <button style={{boxShadow: "0px 0px 4px black", marginTop: "4px", height: "30px", border: "none", cursor: "pointer", padding: "4px"}} onClick={() => setusli(index)}>{group.seatingLocation ? "Update" : "Choose"} Seating Location</button> 
                        <p style={{fontSize: "14px", marginTop: "20px"}}>Current Seating Location: {group.seatingLocation ? group.seatingLocation: "None"}</p>
                        </div>
                        }
                        </div>
                        
                        
                        {group.tables.map((table,i) => {
                            return <div style={{padding: "6px", height: "90px", width: "165px", boxShadow: "0px 0px 4px black", marginTop: "20px"}}>
                                        <p style={{marginTop: "2px", marginLeft: "12px"}}>Table Number: {table.table_num}</p>
                                        <p style={{marginTop: "6px", marginLeft: "12px"}}>Table Capacity: {table.capacity}</p>
                                        <button style={{marginLeft: "30px", marginTop: "16px", padding: "4px", width: "100px", border: "none", boxShadow: "0px 0px 4px black", backgroundColor: "rgb(220, 0, 0, 0.4)", cursor: "pointer"}} onDoubleClick={() => deleteTable(index, i, group.id)}>Delete Table</button>
                                    </div>
                            })}
                        {hitIndex === index && <select onChange={(e) => setTableNum(e.target.value) } style={{padding: "4px", marginTop: "40px", border: "none", width: "130px", boxShadow: "0px 0px 4px black", backgroundColor: "white"}}>
                            <option>Table Number</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                            <option>32</option>
                            <option>33</option>
                            <option>34</option>
                            <option>35</option>
                            <option>36</option>
                            <option>37</option>
                            <option>38</option>
                            <option>39</option>
                            <option>40</option>
                            <option>41</option>
                            <option>42</option>
                            <option>43</option>
                            <option>44</option>
                            <option>45</option>
                            <option>46</option>
                            <option>47</option>
                            <option>48</option>
                            <option>49</option>
                            <option>50</option>
                        </select>}
                        {hitIndex === index && <select onChange={(e) => setCapacity(e.target.value) } style={{padding: "4px", marginTop: "24px", border: "none", boxShadow: "0px 0px 4px black", width: "130px", backgroundColor: "white"}}>
                            <option>Person Capacity</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                        </select>}
                        
                        {hitIndex !== index && <button style={{padding: "4px", marginTop: "20px", height: "28px", boxShadow: "0px 0px 4px black", marginTop: "20px", border: 'none', cursor: 'pointer'}} onClick={() => setHitIndex(index)}>Create Table</button>}
                        {hitIndex === index && <button style={{padding: "4px", marginTop: "20px", height: "28px", boxShadow: "0px 0px 4px black", border: 'none', cursor: 'pointer'}} onClick={() => createNewTable(group.id)}>Finish Table</button>}
                    </div>
                })}

            </div>
        </div>
    )
};
